import {Decoration, MatchDecorator, ViewPlugin} from "@codemirror/view";

export function getJavaAnnotationExtension() {
    let annotationMatcher = new MatchDecorator({
        regexp: /@\w+/g,
        decoration: match => {
            return Decoration.mark({
                class: "annotation",
                inclusiveEnd: true
            })
        },
    })

    return ViewPlugin.fromClass(class {
        constructor(view) {
            this.annotations = annotationMatcher.createDeco(view);
        }

        update(update) {
            if (update.docChanged || update.viewportChanged) {
                this.annotations = annotationMatcher.createDeco(update.view);
            }
        }
    }, {
        decorations: instance => instance.annotations,
    });

}