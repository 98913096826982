import {css, html} from 'lit';
import {BaseTask} from '@whilecat/core/tasks/base-task.js';
import '@whilecat/core/editors/code-area.js';
import {publish} from "@whilecat/core/events/event-service.js";
import {TASK_COMPLETED} from "@whilecat/core/events/task-events.js";

export class LineInputTask extends BaseTask {
    static styles = [
        super.styles,
        css`
          p {
            word-wrap: break-word;
            max-width: 600px;
          }

          .input-block {
            display: flex;
            width: 100%;
          }

          sl-input.input::part(base) {
            font-family: var(--whilecat-mono-font), serif;
          }
        `,
    ];

    static properties = {
        data: {type: Object},
        error: {type: Boolean},
    };

    /**
     * Constructor
     * @param id - id of a task
     * @param {Object} data
     */
    constructor(id, data) {
        super();
        this.id = id;
        this.data = data;
    }

    async inputKeyDown(evt) {
        if (evt.key === "Enter" && evt.metaKey) {
            await this.verify();
            evt.stopPropagation();
        } else {
            this.error = false;
        }
    }

    verify() {
        const enterButton = this.shadowRoot.querySelector("#user-input-button");
        const input = this.shadowRoot.querySelector("#user-input")
        if (this.data.regex != null) {
            this.error = !input.value.trim().match(this.data.regex)
        } else {
            this.error = !this.data.correctAnswer.includes(input.value.trim())
        }
        if (!this.error) {
            input.setAttribute("disabled", "true");
            enterButton.setAttribute("disabled", "true");
            this.complete = true;
            publish(TASK_COMPLETED, this.id);
        }
    }

    showCorrectAnswer() {
        const input = this.shadowRoot.querySelector("#user-input");
        input.value = this.data.correctAnswer[0];
        this.verify();
    }

    render() {
        return html`
          <div>
            ${this.data.content}
            </br>
            <div class="input-block">
              <sl-input class="input"
                        autofocus id="user-input" @keydown='${this.inputKeyDown}' style="width: 100%;"
                        placeholder="type here"
                        autocomplete="off"
                        clearable></sl-input>
              <sl-button id="user-input-button" variant="primary" outline @click="${this.verify}">
                <sl-icon name="arrow-return-right" label="Settings"></sl-icon>
              </sl-button>
            </div>
            ${this.renderErrorText(this.error)}
          </div>
        `;
    }
}

customElements.define('line-input-task', LineInputTask);
