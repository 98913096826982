import {css, CSSResult} from "lit";
import {BaseExtraCodeAreaButton} from "@whilecat/core/editors/buttons/base-extra-button.js";

class ShowAnswerButton extends BaseExtraCodeAreaButton {

    static styles: CSSResult[] = [
        ...super.styles,
        css`
          :host {

          }

          .extra-button:not([disabled]) {
            position: relative;
            z-index: 1;
            animation: appearAnimation ease-in 1s;
          }

          sl-icon-button.extra-button:not([disabled])::part(base) {
            color: var(--sl-color-cyan-600);
          }

          sl-icon-button.extra-button-toggled::part(base) {
            color: var(--sl-color-green-800);
          }

          .extra-button-overlay:not([disabled]) {
            animation: overlayAppearAnimation ease-in 2s;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 25%;
            pointer-events: none;
            z-index: -1;
            box-shadow: 0 0 10px 5px var(--sl-color-cyan-300);
            opacity: 0.7;
          }

          @keyframes overlayAppearAnimation {
            0% {
              border-radius: 100px;
              box-shadow: 0 0 0 0 var(--sl-color-cyan-500)
            }
            50% {
              border-radius: 100px;
              box-shadow: 0 0 10px 7px var(--sl-color-cyan-500)
            }
            100% {
              opacity: 0.7;
              box-shadow: 0 0 10px 5px var(--sl-color-cyan-300);
            }
          }

          @keyframes appearAnimation {
            0% {
              opacity: 0.3;
            }
            100% {
              opacity: 1;
            }
          }
        `
    ]

    static properties: {
        viewButtonCallBack: { type: Function },
    }

    private showAnswerCallBack: any = null

    constructor() {
        super("magic", "stars", "Show answer");
    }

    onClicked() {
        super.playToggleAnimation()

        this.showAnswerCallBack()
    }
}

customElements.define('show-answer-button', ShowAnswerButton);