import {css, html, nothing} from 'lit';
import {BaseTask} from '@whilecat/core/tasks/base-task.js';
import {publish} from "@whilecat/core/events/event-service.js";
import {TASK_COMPLETED} from "@whilecat/core/events/task-events.js";
import {shuffleArray} from "@whilecat/core/utils/arrays.js";
import {isPresent} from "@whilecat/core/utils/objects.js";
import {isNotBlank} from "@whilecat/core/utils/string.js";

const PLACEHOLDER = '@place_holder@';
const EMPTY_BLOCK = '_____';

class CompleteSentenceTask extends BaseTask {
    static styles = [
        super.styles,
        css`
          .hidden {
            opacity: 0;
            pointer-events: none;
            transform: scale(0.9);
          }
        `];

    static properties = {
        data: {type: Object},
        selectedAnswers: {type: Array},
        error: {type: Boolean},
        complete: {type: Boolean},
    };

    constructor(id, data) {
        super();
        this.id = id;
        this.data = data;
        this.selectedAnswers = [];
        this.complete = false;
        this.error = false;
    }

    firstUpdated() {
        const placeholderCount = this.data.sentence.split(PLACEHOLDER).length - 1;
        this.selectedAnswers = Array(placeholderCount).fill(EMPTY_BLOCK);
        this.data.answers = shuffleArray(this.data.answers)
        super.firstUpdated()
    }

    verify() {
        const blankIndex = this.selectedAnswers.indexOf(EMPTY_BLOCK);
        if (blankIndex === -1) {
            this.error = !this.selectedAnswers.every((answer, index) =>
                answer === this.data.rightAnswers[index]);
            if (!this.error) {
                this.complete = true;
                publish(TASK_COMPLETED, this.id);
            }
        }
    }

    renderSentenceParts() {
        const sentenceParts = this.data.sentence.split(PLACEHOLDER);
        return sentenceParts.map((part, index) => html`
          ${part}
          ${index < sentenceParts.length - 1 ? html`
            <sl-button size="small" class="selected-answer"
                       @click="${() => this.unselectAnswer(index)}"
                       ?disabled="${this.complete || this.selectedAnswers[index] === EMPTY_BLOCK}">
              ${this.selectedAnswers[index]}
            </sl-button>
          ` : ''}
        `);
    }

    selectAnswer(answer) {
        const blankIndex = this.selectedAnswers.indexOf(EMPTY_BLOCK);
        if (isPresent(this.data.specialAnswers) && isPresent(this.data.specialAnswers[answer])) {
            this.selectedAnswers[blankIndex] = answer;
            this.specialMessage = this.data.specialAnswers[answer]
        } else if (blankIndex !== -1) {
            this.specialMessage = ""
            this.selectedAnswers[blankIndex] = answer;
            this.verify();
        }
        this.requestUpdate();
    }

    unselectAnswer(index) {
        this.selectedAnswers[index] = EMPTY_BLOCK;
        this.specialMessage = ""
        this.requestUpdate();
    }

    showCorrectAnswer() {
        this.selectedAnswers = this.data.rightAnswers;
        this.verify();
    }

    render() {
        return html`
          ${this.data.content}
          <div>
            <p>${this.renderSentenceParts()}</p>
            ${this.data.answers.map(answer => html`
              <sl-button size="small"
                         class="answer ${this.selectedAnswers.includes(answer) ? 'hidden' : ''}"
                         @click="${() => this.selectAnswer(answer)}"
                         ?disabled="${this.complete || this.selectedAnswers.indexOf(EMPTY_BLOCK) === -1}">
                ${answer}
              </sl-button>
            `)}
          </div>
          <sl-divider></sl-divider>
          ${this.renderErrorText(this.error)}
          ${isNotBlank(this.specialMessage)
                  ? html`
                    <sl-alert class="error-label" variant="primary" open>
                        <sl-icon slot="icon" name="exclamation-circle"></sl-icon>
                        ${this.specialMessage}
                    </sl-alert>
                  `
                  : nothing
          }
          </br>
        `;
    }
}

customElements.define('complete-sentence-task', CompleteSentenceTask);
