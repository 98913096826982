import {css, CSSResult, PropertyDeclarations} from "lit";
import {BaseExtraCodeAreaButton} from "@whilecat/core/editors/buttons/base-extra-button.js";

class ViewButton extends BaseExtraCodeAreaButton {

    static styles: CSSResult[] = [
        ...super.styles,
        css`
          :host {

          }
        `
    ]

    static properties: PropertyDeclarations = {
        ...super.properties,
        viewButtonCallBack: {type: Function},
    }

    private viewButtonCallBack: any = null

    constructor() {
        super("eye", "eye-fill", "Full View", "Partial view");
    }

    onClicked() {
        super.setToggled(!super.isToggled())

        this.viewButtonCallBack();
    }
}

customElements.define('view-button', ViewButton);