export class Course {
    id: number;
    uuid: string;
    name: string;
    image: string;
    subtitle: string;
    description: string;
    url: string;
    hashTags: string[];
    enrolled: boolean;

    constructor(id: number, uuid: string, name: string, url: string, image: string, 
                description: string, hashTags: string[], enrolled: boolean) {
        this.id = id;
        this.uuid = uuid
        this.name = name;
        this.image = image;
        this.subtitle = 'test test ste';
        this.description = description;
        this.url = url;
        this.hashTags = hashTags;
        this.enrolled = enrolled;
    }
};