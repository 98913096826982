import {css, html, LitElement} from 'lit';

/**
 * @customElement error-element
 */
class ErrorElement extends LitElement {
    static styles = css`
      .error {
        text-align: center;
      }
    `

    render() {
        return html`
          <div>
              <div class="course-list">
                <div class="error">
                  <div style="font-size: 80px">🚧</div>
                  Something went wrong. Please try again later.
                </div>  
              </div>
          </div>
        `;
    }
}

customElements.define('error-element', ErrorElement);
