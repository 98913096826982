import "@shoelace-style/shoelace";
import '@shoelace-style/shoelace/dist/themes/dark.css';
import {setBasePath} from "@shoelace-style/shoelace";
import {LitElement} from "lit";

setBasePath("/shoelace/dist/assets");

class UserPage extends LitElement {
    static properties = {
        userData: {type: Object}
    };

    constructor() {
        super();
        this.userData = {};
        document.addEventListener('login-success', (event) => this.handleLoginSuccess(event));
    }

    render() {
        return html`
          <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Age</th>
                        <th>Email</th>
                        <th>Roles</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>${this.userData.name}</td>
                        <td>${this.userData.age}</td>
                        <td>${this.userData.email}</td>
                        
                    </tr>
                </tbody>
            </table>
        `;
    }


    handleLoginSuccess(event) {
        if (event.detail) {
            this.userData = event.detail;
        }
    }
}

customElements.define('user-page', UserPage);