import {gutter, GutterMarker} from "@codemirror/view";

export function getCheckBoxGutter() {
    return gutter({
        lineMarker(view, line) {
            return new class extends GutterMarker {
                toDOM() {
                    const lineNumber = view.state.doc.lineAt(line.from).number;
                    let checkbox = document.createElement('input');
                    checkbox.type = "checkbox";
                    checkbox.id = "line-checkbox";
                    checkbox.setAttribute("line", `${lineNumber}`);
                    if (typeof view.customOptions !== 'undefined') {
                        checkbox.checked = view.customOptions.selected.includes(lineNumber);
                        checkbox.disabled = view.customOptions.disabled;
                        checkbox.addEventListener('change', (event) => {
                            if (event.currentTarget.checked) {
                                view.customOptions.selected.push(lineNumber)
                            } else {
                                view.customOptions.selected = view.customOptions.selected
                                    .filter(item => item !== lineNumber);
                            }
                        })
                    }

                    return checkbox;
                }
            }
        },
        initialSpacer: () => new GutterMarker()
    });
}