import {css, html, nothing} from 'lit';
import {BaseTask} from '@whilecat/core/tasks/base-task.js';
import '@whilecat/core/editors/code-area.js';
import {publish} from "@whilecat/core/events/event-service.js";
import {TASK_COMPLETED} from "@whilecat/core/events/task-events.js";
import {CodeType, compileCode} from "@whilecat/services/java-compiler-service.ts";
import {isBlank, isNotBlank, isNotBlankArray, stringToListItem} from "@whilecat/core/utils/string.js";
import {requireNotNull} from "@whilecat/core/utils/validation.ts";
import {PLACEHOLDER_REGEX} from "@whilecat/core/editors/decorators/placeholder-decorator.ts";
import {isPresent} from "@whilecat/core/utils/objects.js";
import {getRandomElement} from "@whilecat/core/utils/arrays.js";

class VerificationError extends Error {
    constructor(message) {
        super("Verification error");
        this.error = message
    }
}

export class BaseCodeTask extends BaseTask {
    static styles = [
        super.styles,
        css`
          .input-block {
            display: flex;
          }

          .output {
            font-family: monospace;
          }

          placeholder {
            color: var(--sl-color-cyan-600)
          }
        `
    ];

    static properties = {
        data: {type: Object},
        error: {type: Boolean},
        onVerification: {type: Boolean},
        codeType: {type: CodeType},
    };

    /**
     * Constructor
     * @param codeType {CodeType}
     * @param isOneLineEditor {Boolean}
     */
    constructor(codeType, isOneLineEditor) {
        super();
        requireNotNull(codeType)

        this.codeType = codeType
        this.isOneLineEditor = isOneLineEditor
        this.onVerification = false;
        this.output = ""
        this.lastResult = null
        this.failCounter = 0
    }

    setData(data) {
        this.data = data
    }

    async inputKeyDown(evt) {
        if (evt.key === "Enter" && evt.metaKey) {
            evt.stopPropagation();
            await this.verify();
        }
    }

    setInputEnabled(enabled, onVerification) {
        const enterButton = this.shadowRoot.querySelector("#user-input-button");
        const input = this.shadowRoot.querySelector("#user-input");
        this.onVerification = onVerification;
        if (enabled) {
            input.setEditable(true)
            enterButton.removeAttribute("disabled")
        } else {
            input.setEditable(false)
            enterButton.setAttribute("disabled", "true")
        }
    }

    getCode() {
        return this.shadowRoot.querySelector("#user-input").getCode();
    }

    getFullCode() {
        let resultCode = this.getCode();

        if (this.data.preCode != null) {
            resultCode = this.data.preCode + "\n\n" + resultCode;
        }

        if (this.data.postCode != null) {
            resultCode += "\n\n" + this.data.postCode;
        }
        return resultCode;
    }

    checkAnswer(output) {
        return this.data.correctAnswer.includes(output);
    }

    getLastResult() {
        return this.lastResult
    }

    checkFailed(result) {
        return result.result === 'ERROR';
    }

    async verify() {
        this.setInputEnabled(false, true);
        let resultCode = this.getFullCode();

        const isExampleTask = !this.data.editable && isBlank(this.data.correctCode)
        const isCodeMatchesExpected = this.data.editable && this.getCode() === this.data.correctCode
        const isCorrectOutputSupplierDefined = isPresent(this.data.correctAnswerSupplier)
        const isCorrectOutputPredefined =
            isPresent(this.data.correctAnswer) &&
            this.data.correctAnswer.length > 0 &&
            isNotBlankArray(this.data.correctAnswer)

        if ((isCorrectOutputPredefined || isCorrectOutputSupplierDefined) && (isCodeMatchesExpected || isExampleTask)) {
            setTimeout(() => {
                // TODO: at some point we need to prepare the "correct response data"
                // For now it's only possible for the tasks which have correctAnswer from server
                // to respond right away, because JUnit course tasks, for instance, deeply dependent on reports
                if (isCorrectOutputSupplierDefined) {
                    this.reportCompleted(this.data.correctAnswerSupplier())
                } else {
                    this.reportCompleted(getRandomElement(this.data.correctAnswer))
                }

            }, 1000)
            return;
        }

        if (!this.checkPlaceholdersReplaced(resultCode)) {
            setTimeout(() =>
                this.reportFailed(
                    html`
                      <p>
                        Have you forgotten to type the code?</br>
                        Please replace all <placeholder>{Type Here}</placeholder> entries.
                      </p>
                    `), 500)
            return;
        }

        const {response, data} = await compileCode(resultCode, this.codeType);

        try {
            this.lastResult = data;

            // Failed due compilation or other issues
            if (this.checkFailed(data)) {
                this.raiseVerificationException(data.output, null)
            }

            // Failed due wrong output
            if (!this.checkAnswer(data.output) && this.data.correctAnswer.length > 0) {
                this.raiseVerificationException(data.output, this.data.correctAnswer[0])
            }

            // Correct
            this.reportCompleted(data.output)

        } catch (error) {
            if (error instanceof VerificationError) {
                this.reportFailed(error.error)
            } else {
                console.error(error);
                this.reportFailed("Something went wrong. Please try again.")
            }
        } finally {
            if (this.failCounter >= 3) {
                const input = this.shadowRoot.querySelector("#user-input");
                input.enableShowAnswerButton()
            }
        }
    }

    raiseVerificationException(actualOutput, expectedOutput) {
        requireNotNull(actualOutput)

        throw new VerificationError(
            html`

              <p>Output:</p>
              <ul style="list-style: none; padding-left: 0;">
                ${stringToListItem(actualOutput)}</p>
              </ul>

              ${isPresent(expectedOutput) ?
                html`
                        <p>Expected:</p>
                        <ul style="list-style: none; padding-left: 0;">
                        ${stringToListItem(expectedOutput)}
                        </ul>
                      `
                : nothing
            }
            `
        )
    }

    reportFailed(errorText) {
        this.error = true
        this.failCounter++
        this.errorText = errorText
        this.setInputEnabled(true, false);
    }

    reportCompleted(output) {
        this.error = false
        this.outputAvailable = true;
        this.output = html`
          <ul style="list-style: none; padding-left: 0;">
            ${stringToListItem(output)}
          </ul>`;
        this.complete = true;
        publish(TASK_COMPLETED, this.id);
        this.setInputEnabled(this.data.repeatableRun, false);
    }

    checkPlaceholdersReplaced(code) {
        if (PLACEHOLDER_REGEX.test(code)) {
            return false
        }
        return true
    }

    showCorrectAnswer() {
        if (this.data.correctCode) {
            const input = this.shadowRoot.querySelector("#user-input");
            input.setCode(this.data.correctCode);
        }
    }

    renderOutput() {
        if (this.error) {
            return this.renderErrorText(this.error, this.errorText);
        } else if (this.outputAvailable) {
            const practice = this.data.editable && isNotBlank(this.data.correctCode)

            return html`
              <sl-alert variant="${practice ? "success" : "primary"}" open>
                <sl-icon slot="icon"
                         name="${practice ? "check-circle" : "info-circle"}"
                ></sl-icon>
                <strong>Output:</strong><br/>
                <div class="output">${this.output}</div>
              </sl-alert>
            `;
        }
        return '';
    }

    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);

        const input = this.shadowRoot.querySelector("#user-input");

        input.setViewButtonCallBack((toggled) => this.onViewFullCodeToggled(toggled));
        input.setResetButtonCallBack(() => this.onResetCodeButton())
        input.setShowAnswerButtonCallBack(() => this.showCorrectAnswer())
    }

    /**
     * @abstract
     * Should be implemented by child classes
     */
    onViewFullCodeToggled(toggled) {

    }

    onResetCodeButton() {
        const input = this.shadowRoot.querySelector("#user-input");
        input.setCode(this.data.code);
    }

    render() {
        return html`
          <div>
            ${this.data.content}
            </br>
            <div class="input-block">
              <code-area id='user-input'
                         ?editable="${this.data.editable}"
                         style="width: 100%;"
                         @keydown='${this.inputKeyDown}'
                         code=${this.data.code}
                         ?oneLineEditor="${this.isOneLineEditor}"
                         ?rows="${this.isOneLineEditor ? 1 : null}"
                         .linenumbers="${(!this.isOneLineEditor)}"
                         ?viewButtonEnabled="${this.data.viewButtonEnabled}"
                         .tooltips="${this.data.tooltips}"
                         .showAnswerButtonVisible="${this.data.showAnswerButtonEnabled}"
                         .readonlyRanges="${this.data.readonlyRanges}"
              ></code-area>
              <run-button id="user-input-button"
                          buttonText="Run"
                          .onClickFunction="${() => this.verify()}"
                          .processing="${this.onVerification}"
              ></run-button>
            </div>
            ${this.renderOutput()}
          </div>
        `;
    }
}
