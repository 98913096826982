import {css, html} from 'lit';
import {BaseTask} from '@whilecat/core/tasks/base-task.js';
import '@whilecat/core/editors/code-area.js';
import {publish} from "@whilecat/core/events/event-service.js";
import {TASK_COMPLETED} from "@whilecat/core/events/task-events.js";
import {arraysEqual} from "@whilecat/core/utils/arrays.js";

class MarkLinesTask extends BaseTask {
    static styles = css`
      .code-line {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
      }

      .line-checkbox {
        margin-right: 10px;
      }

      pre {
        margin: 0;
      }
    `;

    static properties = {
        data: {type: Object},
        error: {type: String},
        complete: {type: Boolean},
        correctAnswerCallback: {type: Function}
    };

    constructor(id, data) {
        super();
        this.id = id;
        this.data = data;
        this.error = false;
        this.failCounter = 0
    }

    firstUpdated(_changedProperties) {
        const editor = this.shadowRoot.getElementById('editor');
        editor.setShowAnswerButtonCallBack(() => this.showCorrectAnswer())
        super.firstUpdated(_changedProperties);
    }

    updated(changedProperties) {
        super.updated(changedProperties);
    }

    verify() {
        const editor = this.shadowRoot.getElementById('editor');
        const markedLines = editor.getMarkedLines();
        const correct = arraysEqual(this.data.correctLines, markedLines);
        this.error = !correct;
        if (correct) {
            this.complete = true;
            editor.disable();
            publish(TASK_COMPLETED, this.id);
        } else {
            this.failCounter++
        }

        if (this.failCounter >= 3) {
            editor.enableShowAnswerButton()
        }
    }

    showCorrectAnswer() {
        const editor = this.shadowRoot.getElementById('editor');
        editor.markLines(this.data.correctLines)
    }

    render() {
        return html`
          ${this.data.content}
          <code-area id='editor'
                     linenumbers
                     withCheckboxes="true"
                     language="java"
                     code=${this.data.code}
                     .showAnswerButtonVisible="${true}"
          ></code-area>
          ${this.complete ? '' : html`
            <sl-button size="small" variant="primary" @click="${() => this.verify()}">Verify</sl-button>
          `}
          ${this.renderErrorText(this.error)}
        `;
    }
}

customElements.define('mark-lines-task', MarkLinesTask);
