import {Decoration, MatchDecorator, ViewPlugin} from "@codemirror/view";
import "@whilecat/core/editors/decorators/code-note.js"

export function tooltipExtension(words) {
    let tooltipMatcher = new MatchDecorator({
        regexp: new RegExp(`${Object.keys(words).join("|")}`, 'g'),
        decoration: match => {

            const tooltipValue = findValue(words, match)

            if (tooltipValue !== null) {
                return Decoration.mark({
                    tagName: "code-note",
                    attributes: {tooltip: tooltipValue},
                    inclusiveEnd: true
                })
            }

            return Decoration.mark({
                class: "under-stroked",
                inclusiveEnd: true
            })
        },
    })

    return ViewPlugin.fromClass(class {
        constructor(view) {
            this.tooltips = tooltipMatcher.createDeco(view);
        }
    }, {
        decorations: instance => instance.tooltips,
    });
}

function findValue(words, match) {
    for (const [key, value] of Object.entries(words)) {
        if (new RegExp(key).test(match) && value !== "") {
            return value
        }
    }
    return null
}