import {css, html, nothing} from 'lit';
import '@whilecat/core/editors/code-area.js';
import {BaseCodeTask} from "@whilecat/core/tasks/java/base-code-task.js";
import {CodeType} from "@whilecat/services/java-compiler-service.ts";

export class CodeLineInputTask extends BaseCodeTask {
    static styles = [
        super.styles,
        css`
          .input-block {
            display: flex;
            width: 100%;
          }

          .prefix {
            padding: 5px;
            display: flex;
            vertical-align: center;
            align-items: center;
            background-color: var(--whilecat-code-editor-background-color);
            border: 1px solid var(--sl-color-neutral-100);
            border-radius: 10px 0px 0px 10px;
          }

        `,
    ];

    static properties = {
        data: {type: Object},
        error: {type: Boolean},
        onVerification: {type: Boolean},
    };

    constructor() {
        super(CodeType.SCRIPT, true);
    }

    render() {
        return html`
          <div>
            ${this.data.content}
            </br>
            <div class="input-block">
              ${this.data.prefix ?
                      html`
                        <div class="prefix">
                          <java-code>List.of(1,2,3).</java-code>
                        </div>
                      `
                      : nothing
              }
              <code-area id='user-input'
                         ?editable="${this.data.editable}"
                         style="width: 100%;"
                         @keydown='${this.inputKeyDown}'
                         code=${this.data.code}
                         ?oneLineEditor="${this.isOneLineEditor}"
                         ?rows="${this.isOneLineEditor ? 1 : null}"
                         .linenumbers="${(!this.isOneLineEditor)}"
                         ?viewButtonEnabled="${this.data.viewButtonEnabled}"
                         .tooltips="${this.data.tooltips}"
              ></code-area>
              <run-button id="user-input-button"
                          buttonText="Run"
                          .onClickFunction="${() => this.verify()}"
                          .processing="${this.onVerification}"
              ></run-button>
            </div>
            ${this.renderOutput()}
          </div>
        `;
    }
}

customElements.define('code-line-input-task', CodeLineInputTask);
