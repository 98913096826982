import {css, html, LitElement} from 'lit';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import '@whilecat/core/text/text-note.js'

class CodeNote extends LitElement {
    static properties = {
        tooltip: {}
    };

    static styles = css`
      :host {
        display: inline-flex;
      }

      .content {
        max-width: 500px;
        font-size: 12px;
        font-family: var(--whilecat-default-font)
      }
    `;

    constructor() {
        super();
        this.tooltip = "";
    }

    render() {
        return html`
          <text-note>
            <div class="content" slot="tip">
              ${unsafeHTML(this.tooltip)}
            </div>
            <div><slot></slot></div>
          </text-note>
        `;
    }
}

customElements.define('code-note', CodeNote);
