import {EditorView} from "@codemirror/view"
import {HighlightStyle, syntaxHighlighting} from "@codemirror/language"
import {tags as t} from "@lezer/highlight"
import {requireNotNull} from "@whilecat/core/utils/validation.ts";

const chalky = "#e5c07b",
    coral = "#e06c75",
    cyan = "#56b6c2",
    invalid = "#ffffff",
    ivory = "#abb2bf",
    stone = "#7d8799", // Brightened compared to original to increase contrast
    malibu = "#61afef",
    sage = "#98c379",
    whiskey = "#d19a66",
    violet = "#c678dd",
    darkBackground = "#2424287F",
    highlightBackground = "#2c313a",
    background = "#2424287F",
    tooltipBackground = "#353a42",
    selection = "#3E4451",
    cursor = "#528bff"

/// The colors used in the theme, as CSS color strings.
export const color = {
    chalky,
    coral,
    cyan,
    invalid,
    ivory,
    stone,
    malibu,
    sage,
    whiskey,
    violet,
    darkBackground,
    highlightBackground,
    background,
    tooltipBackground,
    selection,
    cursor
}

export function getTheme(isOneLideEditor) {
    requireNotNull(isOneLideEditor)

    return EditorView.theme({
        "&": {
            color: ivory,
            backgroundColor: background
        },

        ".cm-content": {
            caretColor: cursor
        },

        ".cm-cursor, .cm-dropCursor": {borderLeftColor: cursor},
        "&.cm-focused > .cm-scroller > .cm-selectionLayer .cm-selectionBackground, .cm-selectionBackground, .cm-content ::selection": {backgroundColor: selection},

        ".cm-panels": {backgroundColor: darkBackground, color: ivory},
        ".cm-panels.cm-panels-top": {borderBottom: "2px solid black"},
        ".cm-panels.cm-panels-bottom": {borderTop: "2px solid black"},

        ".cm-searchMatch": {
            backgroundColor: "#72a1ff59",
            outline: "1px solid #457dff"
        },
        ".cm-searchMatch.cm-searchMatch-selected": {
            backgroundColor: "#6199ff2f"
        },

        ".cm-activeLine": {backgroundColor: isOneLideEditor ? background : "#6699ff0b"},
        ".cm-selectionMatch": {backgroundColor: "#aafe661a"},

        "&.cm-focused .cm-matchingBracket, &.cm-focused .cm-nonmatchingBracket": {
            backgroundColor: "#bad0f847"
        },

        ".cm-gutters": {
            backgroundColor: "#2424287F",
            color: stone,
            border: "none"
        },

        ".cm-activeLineGutter": {
            backgroundColor: highlightBackground
        },

        ".cm-foldPlaceholder": {
            backgroundColor: "transparent",
            border: "none",
            color: "#ddd"
        },

        ".cm-tooltip": {
            border: "none",
            backgroundColor: tooltipBackground
        },
        ".cm-tooltip .cm-tooltip-arrow:before": {
            borderTopColor: "transparent",
            borderBottomColor: "transparent"
        },
        ".cm-tooltip .cm-tooltip-arrow:after": {
            borderTopColor: tooltipBackground,
            borderBottomColor: tooltipBackground
        },
        ".cm-tooltip-autocomplete": {
            "& > ul > li[aria-selected]": {
                backgroundColor: highlightBackground,
                color: ivory
            }
        }
    }, {dark: true})
}

export const oneDarkHighlightStyle = HighlightStyle.define([
    {
        tag: t.keyword,
        color: "#d08058"
    },
    {
        tag: [t.deleted, t.character, t.propertyName, t.macroName],
        color: "#b773e1"
    },
    {
        tag: [t.name],
        color: ivory
    },
    {
        tag: [t.function(t.variableName), t.labelName],
        color: "#7bb9f3"
    },
    {
        tag: [t.color, t.constant(t.name), t.standard(t.name)],
        color: whiskey
    },
    {
        tag: [t.definition(t.name), t.separator],
        color: ivory
    },
    {
        tag: [t.number, t.className, t.typeName],
        color: "#119EE2FF"
    },
    {
        tag: [t.changed, t.annotation, t.modifier, t.self, t.namespace],
        color: "#d08058"
    },
    {
        tag: [t.operator, t.operatorKeyword, t.url, t.escape, t.regexp, t.link, t.special(t.string)],
        color: ivory
    },
    {
        tag: [t.meta, t.comment],
        color: "#26B559FF"
    },
    {
        tag: t.strong,
        fontWeight: "bold"
    },
    {
        tag: t.emphasis,
        fontStyle: "italic"
    },
    {
        tag: t.strikethrough,
        textDecoration: "line-through"
    },
    {
        tag: t.link,
        color: stone,
        textDecoration: "underline"
    },
    {
        tag: t.heading,
        fontWeight: "bold",
        color: coral
    },
    {
        tag: [t.atom, t.bool, t.special(t.variableName)],
        color: whiskey
    },
    {
        tag: [t.processingInstruction, t.string, t.inserted],
        color: "#26B559FF"
    },
    {
        tag: t.invalid,
        color: invalid
    },
])

export const WHILECAT_CODE_EDITOR_SYNTAX_HIGHLIGHTING = syntaxHighlighting(oneDarkHighlightStyle);