import {getCourseLevels} from "@whilecat/services/course-service.ts";
import {Course} from "@whilecat/services/dto/course.ts";
import {LevelInfo} from "@whilecat/services/dto/level-info.ts";
import {isNotAuthenticated} from "@whilecat/services/authentication-service.ts";
import {mockLevels} from "@whilecat/services/course-levels-mock.ts";
import {SHELL_COURSE_PATH, SHELL_COURSE_UUID} from "../content/shell/shell/levels.ts";
import type {paths} from "@whilecat/generated/openapi/user-course.ts"
import createClient from "openapi-fetch";

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
const client = createClient<paths>({ baseUrl: "/api", headers: {"TimeZone": timezone}});


export async function getUserCourses() {
    const {data, response} = await client.GET("/user/course");

    if (!data) {
        throw new Error(response.statusText);
    }

    return data.map(userCourseInfo => new Course(
        userCourseInfo.course.id,
        userCourseInfo.course.uuid,
        userCourseInfo.course.name,
        userCourseInfo.course.url,
        userCourseInfo.course.img_url,
        userCourseInfo.course.description,
        userCourseInfo.course.tags,
        true,
        )
    ).concat([
        new Course(
            3,
            SHELL_COURSE_UUID,
            "Shell",
            SHELL_COURSE_PATH,
            '/resources/java.png',
            'Learn to use the shell',
            ["shell", "unix", "terminal"],
            true,
        )
    ]);
}


export async function getUserCourseLevels(courseUuid: string) {
    if (isNotAuthenticated()) {
        return await getCourseLevels(courseUuid);
    }

    const mocked = mockLevels.get(courseUuid);
    if (mocked) {
        return mocked;
    }

    try {
        const {data, response} = await client.GET("/user/course/{courseUuid}", {params: {path: {courseUuid}}});

        if (!data) {
            throw new Error(response.statusText);
        }
        const levels = data.userCourseLevels.map(course => new LevelInfo(
                course.courseLevel.id,
                course.courseLevel.name,
                course.courseLevel.url,
                course.completed,
            )
        );
        return {
            ok: true,
            enrolled: (data.id !== null && data.id !== 0),
            levels,
        }
    } catch (e) {
        console.error(e)
        return await getCourseLevels(courseUuid);
    }
}

export async function completeCourseLevel(courseUuid: string, levelUuid: string) {
    await client.POST(
        "/user/course/{courseUuid}/level/{levelUuid}/complete", 
        {params: {path: {courseUuid, levelUuid}}},
    );
}

export async function getUserCourseLevel(courseUuid: string, levelUuid: string) {
    const {data, response} = await client.GET(
        "/user/course/{courseUuid}/level/{levelUuid}", 
        {params: {path: {courseUuid, levelUuid}}},
    );

    return {data, response};
}

export async function rateCourseLevel(courseUuid: string, levelUuid: string, score: number, comment: string) {
    return await client.POST(
        "/user/course/{courseUuid}/level/{levelUuid}/rating", 
        {params: {path: {courseUuid, levelUuid}}, body: {score, comment}},
    )
}

export async function enrollToCourse(courseUuid: string) {
    return await client.POST("/user/course/{courseUuid}", {params: {path: {courseUuid}}});
}

export async function leaveCourse(courseUuid: string) {
    return await client.DELETE('/user/course/{courseUuid}', {params: {path: {courseUuid}}})
}

export async function checkIfLevelAccessible(courseUuid: string, levelUuid: string) {
    if (courseUuid === SHELL_COURSE_UUID) {
        return true;
    }

    const {response} = await client.GET(
        '/user/course/{courseUuid}/level/{levelUuid}/accessible',
        {params: {path: {courseUuid, levelUuid}}},
    )
    return response.ok;
}

export async function completeTask(courseUuid: string, levelUuid: string, taskUuid: string, repeatable: boolean) {
    return await client.POST(
        '/user/course/{courseUuid}/level/{levelUuid}/task/{taskUuid}', 
        {params: {path: {courseUuid, levelUuid, taskUuid}}, body: {repeatable}},
    )
}

export async function getCompletedTasks(courseUuid: string, levelUuid: string) {
    if (courseUuid === SHELL_COURSE_UUID) {
        return [];
    }

    const {response, data} = await client.GET(
        '/user/course/{courseUuid}/level/{levelUuid}/task',
        {params: {path: {courseUuid, levelUuid}}}
    );

    if (!data) {
        throw new Error(response.statusText);
    }

    return data!;
}
