import {css, html, LitElement} from 'lit';

class LoadingIcon extends LitElement {

    static styles = css`
      :host {
        display: block;
      }

      @keyframes changeIcon {
        0% {
          opacity: 0;
          transform: rotate(0) translateX(-100%) translateY(0);
        }
        20% {
          opacity: 1;
          transform: rotate(0) translateX(0) translateY(0);
        }
        37% {
          opacity: 1;
          transform: rotate(180deg) translateX(0) translateY(0);
        }
        50% {
          opacity: 0;
          transform: rotate(180deg) translateY(-100%);
        }
        51% {
          opacity: 0;
          transform: rotate(0) translateX(0) translateY(-100%);
        }
        70% {
          opacity: 1;
          transform: rotate(0) translateX(0) translateY(0);
        }
        87% {
          opacity: 1;
          transform: rotate(-180deg) translateX(0) translateY(0);
        }
        100% {
          opacity: 0;
          transform: rotate(-180deg) translateX(-100%);
        }
      }

      .icon-animation {
        animation: changeIcon 3.5s infinite;
      }
    `;

    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);
        this.runChangeIconAnimation();
    }

    setIcon() {
        const icon = this.shadowRoot.getElementById('loader');
        if (icon == null) {
            return;
        }
        const iconNames = ['square-fill', 'circle-fill', 'envelope-heart-fill', 'arrow-repeat', 'cpu-fill', 'x-lg'];

        this.loaderIndex = (this.loaderIndex + 1) % iconNames.length;
        icon.setAttribute('name', iconNames[this.loaderIndex]);
    }

    runChangeIconAnimation() {
        this.loaderIndex = 1;
        this.setIcon();
        this.animationRunning = true;
        setInterval(() => {
            this.setIcon();
        }, 500);
    }

    render() {
        return html`
          <sl-icon id="loader" class="icon-animation" label="Settings"></sl-icon>
        `;
    }
}

customElements.define('loading-icon', LoadingIcon);
