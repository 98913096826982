import {getJavaAnnotationExtension} from "@whilecat/core/editors/extensions/java-annotation-extension.js";
import {java} from "@codemirror/lang-java";
import {xml} from "@codemirror/lang-xml";
import {defaultKeymap, indentLess, insertTab} from '@codemirror/commands';
import {keymap} from "@codemirror/view";

export function getLanguageExtensions(lang) {
    if (lang === "java" || lang == null) {
        return [
            java(),
            getJavaAnnotationExtension(),
            getKeyBindings({tabEnabled: true})
        ]
    } else if (lang === "xml") {
        return [
            xml()
        ]
    }

    return []
}

function getKeyBindings(configurations) {
    let keyBindings = defaultKeymap;

    if (configurations.tabEnabled) {
        keyBindings = [
            ...keyBindings,
            {
                key: 'Tab',
                preventDefault: true,
                shift: indentLess,
                run: e => {
                    return insertTab(e);
                },
            },]
    }
    return keymap.of(keyBindings)
}
