import {css, CSSResult, html, LitElement, nothing, PropertyDeclarations, PropertyValues} from "lit";
import {requireNotNull} from "@whilecat/core/utils/validation.js";
import type {CodeArea} from "@whilecat/core/editors/code-area.js";

export abstract class BaseExtraCodeAreaButton extends LitElement {

    static styles: CSSResult[] = [
        css`
          :host {

          }

          .extra-button-wrapper {
            position: relative;
            display: inline-block;
          }

          sl-icon-button.extra-button::part(base),
          sl-icon-button.extra-button-toggled::part(base) {
            border: none;
            background: transparent;
            color: var(--sl-color-neutral-500);
            padding-right: 0;
            padding-left: 3px;
          }

          sl-icon-button.extra-button::part(base):hover {
            color: var(--sl-color-cyan-800);
          }

          sl-icon-button.extra-button-toggled::part(base) {
            color: var(--sl-color-cyan-600);
          }

          .extra-button-toggled {
            animation: toggled ease-in-out 0.8s;
            animation-iteration-count: 1;
          }

          @keyframes toggled {
            0% {
              transform: scale(1);
            }
            50% {
              transform: scale(0.8);
            }
            100% {
              transform: scale(1);
            }
          }
        `
    ]

    static properties: PropertyDeclarations = {
        visible: {type: Boolean},
        toggled: {type: Boolean},
        disabled: {type: Boolean},
        onClickHandler: {type: Function},
        tooltip: {type: String},
        iconName: {type: String},
        codeArea: {},
    };

    private visible: boolean = true
    private disabled: boolean = false;
    private toggled: boolean = false;
    private readonly tooltip: string;
    private readonly tooltipToggled: string;
    private readonly iconName: string;
    private readonly toggleIconName: string;
    private readonly codeArea: CodeArea | null;

    protected constructor(iconName: string, toggleIconName: string, tooltip: string, tooltipToggled: string = tooltip) {
        super()
        this.iconName = iconName
        this.toggleIconName = toggleIconName
        this.tooltip = tooltip
        this.tooltipToggled = tooltipToggled
        this.codeArea = null
    }

    protected firstUpdated(_changedProperties: PropertyValues) {
        super.firstUpdated(_changedProperties);

        requireNotNull(this.codeArea)
    }

    disable() {
        this.disabled = true
    }

    enable() {
        this.disabled = false
    }

    getCodeAreaCode() {
        requireNotNull(this.codeArea)
        return this.codeArea.getCode()
    }

    /**
     * @abstract
     */
    onClicked() {

    }

    /**
     * In case if needed, redefine .extra-button-toggled css in derived class
     */
    setToggled(value: boolean) {
        this.toggled = value
    }

    isToggled() {
        return this.toggled
    }

    playToggleAnimation(duration: number = 800) {
        this.setToggled(true)
        this.disable()
        setTimeout(() => {
            this.enable()
            this.setToggled(false)
        }, duration);
    }

    render() {
        if (!this.visible)
            return nothing

        return html`
          <div class="extra-button-wrapper">
              <sl-tooltip content="${this.toggled ? this.tooltipToggled : this.tooltip}">
                <sl-icon-button id="extra-button"
                                name="${this.toggled ? this.toggleIconName : this.iconName}"
                                class="${this.toggled ? "extra-button-toggled" : "extra-button"}"
                                ?disabled="${this.disabled}"
                                @click="${() => this.onClicked()}"></sl-icon-button>
              </sl-tooltip>
              <div class="extra-button-overlay"
                   ?disabled="${this.disabled}"
              ></div>
          </div>
        `
    }
}