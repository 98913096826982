import {css, html, LitElement} from 'lit';
import '@whilecat/core/navigation/level-navigation.js';
import '@whilecat/core/navigation/page-header.js';
import {subscribe} from "@whilecat/core/events/event-service.js";
import {LEVEL_ACTION_REQUIRED} from "@whilecat/core/events/task-events.js";
import {enrollToCourse, leaveCourse} from "@whilecat/services/user-course-service.ts";
import {redirectToLoginOnUnauthorized} from "@whilecat/services/authentication-service.ts";
import '@whilecat/core/loaders/loading-element.js'

const ENROLL_COURSE = "Start the Course";
const LEAVE_COURSE = "Leave the Course";

class CourseLevels extends LitElement {

    static styles = css`
      :host {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        min-height: 400px; /* to allow navigation level float at right position from the beginning */
        margin-top: var(--whilecat-page-header-magin);
      }

      .loader {
        grid-column: 2;
        grid-row: 2;
      }

      sl-dialog.leaveCourseDialog::part(overlay) {
        background-color: rgb(26, 26, 30, 0.7);
        backdrop-filter: blur(5px);
      }

      sl-dialog.leaveCourseDialog::part(panel) {
        margin-left: var(--whilecat-navigation-panel-width);
      }

      sl-dialog.leaveCourseDialog::part(panel) {
        --header-spacing: 5px;
        --body-spacing: 5px;
        --footer-spacing: 5px;
        --width: 400px;
        padding: 20px;
        background-color: var(--whilecat-background-color);
        border: var(--whilecat-default-border-size) solid var(--sl-color-neutral-100);
        border-radius: var(--sl-border-radius-x-large);
        font-family: var(--whilecat-default-font);
        border-top: none;
        border-bottom: none;
      }

      sl-dialog.leaveCourseDialog::part(panel):hover {
        border: var(--whilecat-default-border-size) solid var(--sl-color-primary-100);
        border-top: none;
        border-bottom: none;
      }

      .levels {
        display: block;
        margin-top: 40px;
        grid-column: 2 / 3;
        justify-self: center;
      }

      .level-navigation {
        grid-column: 3 / 4;
        justify-self: end;
      }


      svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
      }

      .page-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 70px;
      }

      .grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas: "left center right";
        gap: 80px;
        margin-bottom: 90px;
        max-width: 900px;
        min-width: 300px;
      }

      .left {
        grid-area: left;
        max-width: 100px;
      }

      .center {
        grid-area: center;
        max-width: 100px;
      }

      .right {
        grid-area: right;
        max-width: 100px;
      }

      .completed, .not-completed, .in-progress {
        min-width: 100px;
        height: 40px;
        border-radius: 5px;
        font-size: max(13px, 0.75em);
        font-weight: var(--sl-font-weight-semibold);
        font-family: var(--whilecat-default-font);
        display: inline-flex;
        align-items: center;
        text-align: center;
        justify-content: center;
      }

      .completed {
        color: var(--sl-color-neutral-0);
        background-color: var(--sl-color-primary-200);
        box-shadow: 0 0.2em var(--sl-color-primary-100), 0 0.4em rgba(0, 0, 0, 0.4);
      }

      .completed:hover {
        background-color: var(--sl-color-primary-500);
      }

      .in-progress:hover {
        background-color: var(--sl-color-primary-700);
      }

      .in-progress {
        color: black;
        background-color: var(--sl-color-primary-600);
        border: 1px solid var(--sl-color-primary-300);
        box-shadow: 0 0.2em var(--sl-color-primary-300), 0 0.4em rgba(0, 0, 0, 0.4), 0 0 50px -12px var(--sl-color-primary-500);
      }

      .not-completed {
        color: var(--sl-color-neutral-500);
        background-color: var(--sl-color-gray-200);
        box-shadow: 0 0.3em var(--sl-color-gray-50), 0 0.4em rgba(0, 0, 0, 0.4);
      }
    `;

    static properties = {
        courseId: {type: String},
        levels: {},
        courseName: {type: String},
        enrolled: {type: Boolean},
        loading: {type: Boolean},
    };

    constructor() {
        super();
        this.path = "";
        this.courseName = "";
        this.disabledPath = "";
        this.microChipPath = "";
        this.disabledMicroChipPath = "";
        this.linePointElements = [];
        this.enrolled = false;
        this.levels = [];
        this.loading = true;
        this.levelsPlacement = [
            ['center'],
            ['center'],
            ['right'],
            ['center', 'left'],
            ['center', 'right'],
        ]

        setTimeout(() => this.updatePaths(), 200);
        window.addEventListener('resize', () => {
            this.updatePaths();
        });

        subscribe(LEVEL_ACTION_REQUIRED, async (event) => {
            redirectToLoginOnUnauthorized()

            switch (event.detail) {
                case LEAVE_COURSE:
                    this.getLeaveCourseDialog().show();
                    return
                case ENROLL_COURSE:
                    const {response} = await enrollToCourse(this.courseId)
                    if (response.ok) {
                        location.reload();
                    }
                    return;
                default:
                    console.error("Unknown action: ", event.detail);
                    return;
            }
        })
    }

    getLeaveCourseDialog() {
        return this.shadowRoot.getElementById("leaveCourseDialog");
    }

    async leaveCourse() {
        redirectToLoginOnUnauthorized()
        const {response} = await leaveCourse(this.courseId);
        if (response.ok) {
            location.reload();
        }
    }

    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);
        this.drawLevels();
    }

    update(changedProperties) {
        if (changedProperties.has("levels")) {
            this.drawLevels();
        }
        super.update(changedProperties);
    }

    async drawLevels() {
        let root = this.shadowRoot.getElementById("levels");
        if (!root) {
            return;
        }

        let i = 0;
        let j = 0;

        let currentGrid = null;
        let previousCompleted = false;

        for (let levelIndex = 0; levelIndex < this.levels.length; levelIndex++) {
            const level = this.levels[levelIndex];
            if (j === 0) {
                currentGrid = document.createElement("div");
                currentGrid.setAttribute("class", "grid-container");
                root.appendChild(currentGrid);
            }

            let levelElement = document.createElement("level-badge");
            let classes = "point " + this.levelsPlacement[i][j];

            if (level.completed) {
                classes += " completed";
            } else if (!level.completed && (previousCompleted || levelIndex === 0)) {
                classes += " in-progress";
            } else {
                classes += " not-completed";
            }

            if (previousCompleted || level.completed || levelIndex === 0) {
                levelElement.onclick = () => this.redirectToLevel(level);
            }
            levelElement.setAttribute("class", classes);
            levelElement.innerText = level.levelName;

            currentGrid.appendChild(levelElement);

            j++;
            if (j >= this.levelsPlacement[i].length) {
                i++;
                j = 0;
            }
            if (i >= this.levelsPlacement.length) {
                i = 0;
                j = 0;
            }
            previousCompleted = level.completed;
        }

        this.updatePaths();
    }

    redirectToLevel(level) {
        const url = window.location.origin + level.levelUrl + "?completed=" + level.completed;
        window.history.pushState(null, document.title, url);
        window.dispatchEvent(new PopStateEvent('popstate'));
    }

    updated(changedProperties) {
        this.linePointElements = this.shadowRoot.querySelectorAll(".point");
    }

    updatePaths() {
        this.path = "";
        this.microChipPath = "";
        this.disabledMicroChipPath = "";
        this.disabledPath = "";
        let previousPoint = null;
        let prevDiv = null;
        for (let i = 0; i < this.linePointElements.length - 1; i++) {
            let divA = this.linePointElements[i];
            let divB = this.linePointElements[i + 1];

            let positionA = previousPoint == null ? {
                x: divA.offsetLeft + divA.offsetWidth / 2,
                y: divA.offsetTop + divA.offsetHeight / 2
            } : previousPoint;
            let positionB = {
                x: divB.offsetLeft + divB.offsetWidth / 2,
                y: divB.offsetTop + divB.offsetHeight / 2,
            };

            const attributes = divA.getAttribute("class");
            if (attributes.includes("center")) {
                this.path += `M${positionA.x},${positionA.y} L${positionB.x},${positionA.y} L${positionB.x},${positionB.y}`
            } else {
                this.path += `M${positionA.x},${positionA.y} L${positionA.x},${positionB.y} L${positionB.x},${positionB.y}`
            }

            previousPoint = positionB;
            prevDiv = divB;

            if (i === 0) {
                this.drawMicroChip(divA);
            }
            this.drawMicroChip(divB);
        }

        this.requestUpdate();
    }

    circlePath(cx, cy, r) {
        return 'M ' + cx + ' ' + cy + ' m -' + r + ', 0 a ' + r + ',' + r + ' 0 1,1 ' + (r * 2) + ',0 a ' + r + ',' + r + ' 0 1,1 -' + (r * 2) + ',0';
    }

    drawMicroChip(divB) {
        const directions = [
            {dx: 1 / 3, dy: 0, dx1: 1 / 3, dy1: -1 / 3, xDiv: 1, yDiv: 2, cDiv: 4, xOffset: -5, yOffset: 0}, // left up
            {dx: 1 / 2, dy: 0, dx1: 1 / 2, dy1: -0.7, xDiv: 1, yDiv: 2, cDiv: 4, xOffset: -8, yOffset: 0}, // center up
            {dx: 2 / 3, dy: 0, dx1: 2 / 3, dy1: -0.5, xDiv: 1, yDiv: 2, cDiv: 5, xOffset: 5, yOffset: 0}, // right up
            //
            {dx: 0, dy: 1 / 4, dx1: -0.25, dy1: 1 / 4, xDiv: 2, yDiv: 1, cDiv: 3, xOffset: 0, yOffset: -5}, // upper left
            {dx: 0, dy: 1 / 2, dx1: -0.4, dy1: 1 / 2, xDiv: 2, yDiv: 1, cDiv: 3, xOffset: 0, yOffset: -6}, // center left
            {dx: 0, dy: 3 / 4, dx1: -0.3, dy1: 3 / 4, xDiv: 2, yDiv: 1, cDiv: 3, xOffset: 0, yOffset: 5}, // bottom left
            //
            {dx: 1, dy: 1 / 4, dx1: 1.25, dy1: 1 / 4, xDiv: 1.05, yDiv: 1, cDiv: 1.2, xOffset: 0, yOffset: -5}, // upper right
            {dx: 1, dy: 1 / 2, dx1: 1.4, dy1: 1 / 2, xDiv: 1.1, yDiv: 1, cDiv: 1.1, xOffset: 0, yOffset: 7}, // center right
            {dx: 1, dy: 0.75, dx1: 1.25, dy1: 0.75, xDiv: 1.15, yDiv: 1, cDiv: 1.15, xOffset: 0, yOffset: 8}, // bottom right
            //
            {dx: 1 / 3, dy: 1, dx1: 1 / 3, dy1: 4 / 3, xDiv: 1, yDiv: 1, cDiv: 1.3, xOffset: -5, yOffset: 0}, // left down
            {dx: 1 / 2, dy: 1, dx1: 1 / 2, dy1: 5 / 3, xDiv: 1, yDiv: 1.1, cDiv: 1.3, xOffset: -5, yOffset: 0}, // center down
            {dx: 2 / 3, dy: 0.9, dx1: 2 / 3, dy1: 1.5, xDiv: 1, yDiv: 1.1, cDiv: 1.3, xOffset: -5, yOffset: 0}, // right downf
        ];

        directions.forEach(dir => {
            const startX = divB.offsetLeft + divB.offsetWidth * dir.dx;
            const startY = divB.offsetTop + divB.offsetHeight * dir.dy;

            const targetX = divB.offsetLeft + divB.offsetWidth * dir.dx1;
            const targetY = divB.offsetTop + divB.offsetHeight * dir.dy1;

            const middleX1 = dir.xOffset !== 0 ? startX : (divB.offsetLeft + divB.offsetWidth * dir.dx1 / dir.xDiv / dir.cDiv);
            const middleY1 = dir.yOffset !== 0 ? startY : (divB.offsetTop + divB.offsetHeight * dir.dy1 / dir.yDiv / dir.cDiv);

            const middleX2 = dir.xOffset !== 0 ? startX + dir.xOffset : (divB.offsetLeft + divB.offsetWidth * dir.dx1 / dir.cDiv);
            const middleY2 = dir.yOffset !== 0 ? startY + dir.yOffset : (divB.offsetTop + divB.offsetHeight * dir.dy1 / dir.cDiv);

            let path = `M${startX},${startY} L${middleX1},${middleY1}`;
            path += `M${middleX1},${middleY1} L${middleX2},${middleY2}`;
            path += `M${middleX2},${middleY2} L${targetX + dir.xOffset},${targetY + dir.yOffset}`;
            path += this.circlePath(targetX + dir.xOffset, targetY + dir.yOffset, 3);

            if (divB.getAttribute("class").includes("not-completed")) {
                this.disabledMicroChipPath += path;
            } else {
                this.microChipPath += path;
            }
        });
    }

    getCompletedCount() {
        return this.levels.filter(value => value.completed).length;
    }

    getActions() {
        return [this.enrolled ? LEAVE_COURSE : ENROLL_COURSE];
    }

    render() {
        return html`
          <svg id="connector" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
            <path d=${this.path} fill="none" stroke="var(--sl-color-primary-50)" stroke-dasharray="10,9"
                  stroke-width="2"></path>
          </svg>
          <svg id="connector" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
            <path d=${this.disabledPath} fill="none" stroke="var(--sl-color-gray-200)" stroke-width="3"></path>
          </svg>
          <svg id="connector" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
            <path d=${this.microChipPath} fill="none" stroke="var(--sl-color-primary-200)" stroke-width="3"></path>
          </svg>
          <svg id="connector" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
            <path d=${this.disabledMicroChipPath} fill="none" stroke="var(--sl-color-gray-200)" stroke-width="3"></path>
          </svg>
          <div id="levels" class="levels"></div>
          <level-navigation class="level-navigation"
                            .completedCount="${this.getCompletedCount()}"
                            .totalCount="${this.levels.length}"
                            .navigationAnchors="${[]}"
                            .actions="${this.getActions()}">
          </level-navigation>
          <sl-dialog id="leaveCourseDialog" no-header class="leaveCourseDialog">
            <h3>
              <sl-icon name="question-octagon" style="font-size: 14px; color: #f5a623;"></sl-icon>
              Are you sure you want to leave?
            </h3>
            <p>Your progress will be lost...</p>
            <sl-button slot="footer" variant="neutral" size="small" outline @click="${async () => this.leaveCourse()}">Yes
            </sl-button>
            <sl-button slot="footer" variant="primary" size="small" outline
                       @click="${() => this.getLeaveCourseDialog().hide()}">
              Cancel
            </sl-button>
          </sl-dialog>
        `;
    }
}

customElements.define('course-levels', CourseLevels);
