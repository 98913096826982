import {html, LitElement} from 'lit';
import {getUserCourseLevels} from "@whilecat/services/user-course-service.ts";
import {setHeader, unsetHeader} from "@whilecat/core/navigation/page-header.js";
import "@whilecat/core/loaders/loading-element.js"
import "./course-levels.js"

export class BaseCourseLevels extends LitElement {

    static properties = {
        _levels: {},
        _enrolled: {type: Boolean},
        loading: {type: Boolean},
        loadingFailed: {type: Boolean},
    };

    constructor(courseId, headerText) {
        super();
        this.courseId = courseId
        this._levels = [];
        this._enrolled = false;
        this.headerText = headerText;
        this.loading = true;
        this.loadingFailed = false;
    }

    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);
        this.loadUserCourseLevels();
    }

    async loadUserCourseLevels() {
        const result = await getUserCourseLevels(this.courseId);
        if (result.ok) {
            this._enrolled = result.enrolled;
            this._levels = result.levels;
            this.loading = false;
        } else {
            this.loadingFailed = true;
        }
    }

    onBeforeEnter(location, commands, router) {
        setHeader(this.headerText);
    }

    onBeforeLeave(location, commands, router) {
        unsetHeader();
    }

    render() {
        if (this.loading) {
            return html`
              <loading-element class="loader" ?failed="${this.loadingFailed}"></loading-element>
            `
        }
        return html`
          <course-levels .courseId="${this.courseId}"
                         .levels="${this._levels}"
                         .enrolled="${this._enrolled}"
                         courseName="${this.headerText}"></course-levels>
        `
    }
}
