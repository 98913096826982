import {css, html, LitElement} from 'lit';
import '@whilecat/core/loaders/loading-icon.js'
import '@whilecat/core/elements/errors/error-element.js'

class LoadingElement extends LitElement {

    static styles = css`
      :host {
        display: flex;
        justify-content: center;
        text-align: center;
        color: var(--sl-color-primary-600);
        font-size: 20px;
      }

      #loading {
        font-size: 40px;
        padding: 20px;
      }
    `;

    static properties = {
        failed: {type: Boolean},
    };

    constructor() {
        super();
        this.failed = false;
    }

    render() {
        if (this.failed) {
            return html`
              <error-element></error-element>`
        }
        return html`
          <div>
            Loading
            <loading-icon id="loading"></loading-icon>
          </div>
        `;
    }
}

customElements.define('loading-element', LoadingElement);
