import {requireNotNull} from "@whilecat/core/utils/validation.ts";

/**
 * @returns The full URL of the page, e.g. 'http://localhost/courses/java/concurrency'
 */
export function getCurrentLocation() {
    return window.location.href;
}

/**
 * @returns URL Path of the page, e.g. '/courses/java/concurrency'
 */
export function getCurrentPath() {
    return window.location.pathname;
}

export function redirectTo(newLocation) {
    window.location.assign(newLocation);
}

export function redirectToCourses() {
    redirectTo("/courses")
}

export function redirectToProfile() {
    redirectTo("/profile")
}

export function redirectToLogin() {
    redirectTo("/login")
}

export function routeTo404(commands) {
    requireNotNull(commands)
    return commands.redirect("/404");
}

export function routeToLogin(commands) {
    requireNotNull(commands)
    localStorage.setItem('previousPage', window.location.href);
    return commands.redirect(`/login`);
}

export function getQueryParameter(name) {
    const params = new URLSearchParams(window.location.search);
    return params.get(name);
}

export function openNewTab(url) {
    window.open(url, '_blank');
}