import {BaseCourseLevels} from "@whilecat/core/elements/course-levels/base-course-levels.js"
import {defineCustomElement} from "@whilecat/core/utils/custom-elements-utils.js";

export const SHELL_COURSE_UUID = "dd2f9319-bce9-4e43-8b45-12685ce6c6e2"
export const SHELL_COURSE_PATH = "/courses/shell/shell"

export function getCourseUuid() {
    return SHELL_COURSE_UUID
}

class FindDogJourneys extends BaseCourseLevels {
    constructor() {
        super(SHELL_COURSE_UUID, "Shell Shell");
    }
}

defineCustomElement('shell-shell-levels', FindDogJourneys);
