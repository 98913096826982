import {css, CSSResult, PropertyDeclarations} from "lit";
import {BaseExtraCodeAreaButton} from "@whilecat/core/editors/buttons/base-extra-button.js";

class ResetButton extends BaseExtraCodeAreaButton {

    static styles: CSSResult[] = [
        ...super.styles,
        css`
          :host {

          }

          .extra-button-toggled {
            animation: toggled ease-in-out 0.8s, rotate ease-in 0.8s;
            animation-iteration-count: 1;
          }

          @keyframes rotate {
            0% {
              transform: rotate(0deg);
            }
            50% {
              transform: rotate(-1440deg);
              animation-timing-function: ease-out;
            }
            100% {
              transform: rotate(-1800deg);
              animation-timing-function: ease-in;
            }
          }
        `
    ]

    static properties: PropertyDeclarations = {
        ...super.properties,
        resetButtonCallBack: {type: Function},
    }

    private resetButtonCallBack: any = null

    constructor() {
        super("arrow-counterclockwise", "tropical-storm", "Reset Code");
    }

    onClicked() {
        super.playToggleAnimation()

        this.resetButtonCallBack();
    }
}

customElements.define('reset-button', ResetButton);