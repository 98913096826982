import {WidgetType} from "@codemirror/view";
import {EditorView} from "codemirror";

import "./placeholder-element.js"

export class PlaceholderWidget extends WidgetType {
    private textTyped = false
    constructor(private placeholderText: string) {
        super();
    }

    toDOM(view: EditorView): HTMLElement {
        const span = document.createElement("placeholder-element");

        span.addEventListener("click", () => {
            const pos = view.posAtDOM(span);
            view.dispatch({
                changes: {from: pos, to: pos + this.placeholderText.length},
                selection: {anchor: pos}
            });

            this.textTyped = false;
        });

        return span;
    }

    restore(view: EditorView, pos: number) {
        view.dispatch({
            changes: {from: pos, insert: this.placeholderText}
        });
    }
}
