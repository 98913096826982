import {LevelInfo} from "@whilecat/services/dto/level-info.ts";
import {SHELL_COURSE_UUID} from "../content/shell/shell/levels.ts";


export const mockLevels = new Map(
    [[
        SHELL_COURSE_UUID,
        {
            ok: true,
            enrolled: true,
            levels: [
                "Learning to Read",
                "Learning to Write",
                "Getting Organised",
                "Getting Rid of Things",
                "Relative or Absolute",
                "Copying",
                "Pipelines",
                "Environment variables",
                "History",
            ].map((title, index) => new LevelInfo(
                index+1,
                title,
                `/courses/shell/shell/lesson-${index+1}`,
                index < 8, // Is completed?
            )),
        }
    ]]
);