import {css, html, nothing} from 'lit';
import {BaseTask} from '@whilecat/core/tasks/base-task.js';
import '@whilecat/core/editors/code-area.js';
import {publish} from "@whilecat/core/events/event-service.js";
import {TASK_COMPLETED} from "@whilecat/core/events/task-events.js";
import {isPresent} from "@whilecat/core/utils/objects.js";

export class QuizTask extends BaseTask {
    static styles = [
        super.styles,
        css`
          sl-button {
            width: 100%;
            padding: 2px;
          }

          sl-button.correct-option::part(base) {
            background: var(--sl-color-green-600);
            color: black;
          }

          sl-button.error-option::part(base) {
            border: 2px solid var(--sl-color-red-400);
          }

          sl-button.almost-true-option::part(base) {
            border: 2px solid var(--sl-color-yellow-400);
          }

        `,
    ];

    static properties = {
        data: {type: Object},
        error: {type: Boolean},
        message: {type: String},
    };

    /**
     * Constructor
     * @param id
     * @param {Object} data
     */
    constructor(id, data) {
        super();
        this.id = id;
        this.data = data;
        this.selectedAnswer = '';
        this.message = null
    }

    verify(answer) {
        this.selectedAnswer = answer;
        this.error = answer !== this.data.correctAnswer;
        this.message = null

        const almostTrueOption = this.data.almostTrueOptions?.get(answer) ?? null
        if (almostTrueOption !== null) {
            this.message = almostTrueOption
        }

        if (!this.error) {
            this.complete = true;
            publish(TASK_COMPLETED, this.id);
        }
        this.requestUpdate();
    }

    renderOption(answer) {
        let variant = 'default';
        if (this.selectedAnswer) {
            if (this.selectedAnswer === answer) {
                if (this.complete) {
                    variant = "correct-option"
                } else if (this.message != null) {
                    variant = "almost-true-option"
                } else {
                    variant = "error-option"
                }
            }
        }

        return html`
          <sl-button
                  size="small"
                  class="option ${variant}"
                  @click="${() => this.verify(answer)}"
                  ?disabled="${this.complete}"
          >
            ${answer}
          </sl-button>
        `;
    }

    showCorrectAnswer() {
        this.selectedAnswer = this.data.correctAnswer;
        this.verify(this.selectedAnswer)
    }

    _completeTask() {
        this.showCorrectAnswer()
    }

    renderMessage() {
        if (!this.error)
            return nothing;

        return this.message != null
            ? html`
                  <sl-alert class="error-label" variant="warning" open>
                    <sl-icon slot="icon" name="exclamation-octagon"></sl-icon>
                    ${this.message}
                  </sl-alert>
            `
            : this.renderErrorText(this.error);
    }

    render() {
        const codeArea = isPresent(this.data.code) && this.data.code.length > 0 ?
            html`
              <code-area code="${this.data.code}" language="java" linenumbers></code-area>`
            : nothing;

        return html`
          <div>
            <p>${this.data.question}</p>
            ${this.data.content}
            ${codeArea}
            <div class="options">
              ${this.data.options.map((answer) => this.renderOption(answer))}
            </div>
          </div>
          </br>
          ${this.renderMessage()}
        `;
    }
}

customElements.define('quiz-task', QuizTask);
