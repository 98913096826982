import {css, html, LitElement} from 'lit';
import {redirectToProfile} from "@whilecat/core/utils/location-utils.js";
import {isAuthenticated, logout} from "@whilecat/services/authentication-service.ts";

export function setHeader(text) {
    document.getElementById("page-header").setAttribute("headerContent", text);
}

export function unsetHeader() {
    document.getElementById("page-header").setAttribute("headerContent", "");
}

class PageHeader extends LitElement {
    static styles = css`
      .page-header {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        align-items: center;
        grid-template-rows: 50px;
        width: 100%;
        top: 0;
        position: fixed;
        border-bottom: 1px solid var(--sl-color-gray-50);
        backdrop-filter: blur(6px);
        z-index: 600;
        background-color: transparent;
        height: var(--whilecat-page-header-height);
      }

      .headerContent {
        grid-column: 2 / 3;
        justify-self: center;
      }

      .profile-container {
        grid-column: 3 / 4;
        justify-self: end;
        margin-right: 20px;
      }

      .menu {
        background-color: var(--whilecat-background-color);
      }

      sl-button.login-button::part(base) {
        border: none;
      }

      @media screen and (min-width: 1200px) {
        .headerContent {
          margin-left: var(--whilecat-navigation-panel-width);
        }
      }
    `;

    static properties = {
        headerContent: {type: String},
    };

    logOut() {
        logout()
    }

    getProfileButton() {
        if (isAuthenticated()) {
            return html`
              <sl-dropdown id="user-menu-button">
                <sl-button slot="trigger" class="login-button" pill>
                  <sl-icon slot="prefix" name="person"></sl-icon>
                </sl-button>
                <sl-menu id="userMenu" class="menu">
                  <sl-menu-item class="menu" @click="${() => redirectToProfile()}">
                    Profile
                  </sl-menu-item>
                  <sl-menu-item class="menu">
                    Subscription
                  </sl-menu-item>
                  <sl-divider></sl-divider>
                  <sl-menu-item id="logout-button" @click="${this.logOut}">
                    <sl-icon slot="prefix" name="box-arrow-right"></sl-icon>
                    Log out
                  </sl-menu-item>
                </sl-menu>
              </sl-dropdown>
              </div>
            `
        }
        return html`
          <sl-button class="login-button" pill href="/login">
            <sl-icon slot="prefix" name="box-arrow-in-right"></sl-icon>
            Log in
          </sl-button>
        `
    }

    render() {
        return html`
          <div class="page-header">
            <div class="headerContent">
              <h2>${this.headerContent}</h2>
            </div>
            <div class="profile-container">
              ${this.getProfileButton()}
          </div>
        `;
    }
}

customElements.define('page-header', PageHeader);
