import {css, CSSResult} from "lit";
import {BaseExtraCodeAreaButton} from "@whilecat/core/editors/buttons/base-extra-button.js";
import {requireNotNull} from "@whilecat/core/utils/validation.js";

class CopyButton extends BaseExtraCodeAreaButton {

    static styles: CSSResult[] = [
        ...super.styles,
        css`
          :host {

          }
        `
    ]

    constructor() {
        super("copy", "check-lg", "Copy");
    }

    onClicked() {
        requireNotNull(this.shadowRoot)

        navigator.clipboard.writeText(super.getCodeAreaCode());

        super.playToggleAnimation(1500)
    }
}

customElements.define('copy-button', CopyButton);