import {
    deleteUserId,
    getUserId,
    setRegistrationTokenCookie,
    setResetPasswordToken
} from "@whilecat/core/utils/cookie-utils.js";
import {getCurrentLocation, getQueryParameter, redirectTo} from "@whilecat/core/utils/location-utils.js";
import {requireNotBlank, requireNotNull} from "@whilecat/core/utils/validation.ts";
import {isBlank} from "@whilecat/core/utils/string.js";
import {HttpStatus} from "@whilecat/services/response-codes.js";
import type {paths} from "@whilecat/generated/openapi/authentication.ts"
import {Client} from "openapi-fetch";
import {createWhileCatClient} from "@whilecat/services/client-utils.js";

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
let client: Client<paths> | null = null

export async function login(email: string, password: string) {
    // parseAs needed for the lib to not read the response body, which can only be read once.
    const {data, response} = await getClient().POST("/public/authentication/", {
        body: {email, password},
        parseAs: 'stream'
    });

    if (!data) {
        throw new Error(response.statusText);
    }

    return response!;
}

export async function logout() {
    await getClient().DELETE("/public/authentication/");
    deleteUserId()
    redirectTo(getCurrentLocation())
}

export async function register(email: string, password: string) {
    // parseAs needed for the lib to not read the response body, which can only be read once.
    const {response} = await getClient().POST("/public/authentication/registration", {
        body: {email, password},
        parseAs: 'stream'
    });

    return response;
}

export async function completeRegistration() {
    setRegistrationTokenCookie(getQueryParameter("token"))

    // parseAs needed for the lib to not read the response body, which can only be read once.
    const {
        data,
        response
    } = await getClient().POST("/public/authentication/registration/complete", {parseAs: 'stream'});

    return response;
}


export async function resendConfirmationLetter(email: string) {
    const useEmail = requireNotBlank(isBlank(email) ? getQueryParameter("email") : email);

    const {data, response} = await getClient().POST(
        "/public/authentication/registration/resend", 
        {params: {query: {email: useEmail}}},
    );

    return {data, response}
}

export async function sendPasswordRecoveryLetter(email: string) {
    const useEmail = requireNotBlank(isBlank(email) ? getQueryParameter("email") : email);

    const {data, response} = await getClient().POST(
        "/public/authentication/recover-password", 
        {params: {query: {email: useEmail}}},
    );

    return {data, response}
}

export async function getGoogleAuthenticationLink() {
    const {response} = await getClient().GET(
        "/public/authentication/google/link",
        {headers: {'accept': 'text/plain'}, parseAs: 'stream'},
    );
    return response;
}

export async function getGitHubAuthenticationLink() {
    const {response} = await getClient().GET(
        "/public/authentication/github/link", 
        {headers: {'accept': 'text/plain'}, parseAs: 'stream'},
    );
    return response;
}

async function externalAuthentication(type: "github" | "google") {
    const code = getQueryParameter("code");
    requireNotNull(code);

    const url = type === "github" ? "/public/authentication/github" : "/public/authentication/google"
    const {response} = await getClient().POST(url, {params: {query: {code}}})
    if (response.ok) {
        redirectTo("/courses")
    } else if (response.status === HttpStatus.BAD_REQUEST) {
        return response.json()
    }
    return response
}

export async function resetPassword(password: string) {
    const token = requireNotBlank(getQueryParameter("token"))
    const email = requireNotBlank(getQueryParameter("email"))

    requireNotBlank(password)

    setResetPasswordToken(token)

    return await getClient().POST("/public/authentication/reset-password", {body: {email, password}})
}

export async function updatePassword(email: string, password: string) {
    requireNotBlank(email)
    requireNotBlank(password)

    return await getClient().POST("/public/authentication/reset-password", {body: {email, password}})
}

export async function googleAuthenticationProceed() {
    return await externalAuthentication("google")
}

export async function gitHubAuthenticationProceed() {
    return await externalAuthentication("github")
}

export function redirectToLoginOnUnauthorized() {
    if (isNotAuthenticated()) {
        redirectTo(`/login?redirect=${getCurrentLocation()}`);
        return true;
    }
    return false;
}

export function isAuthenticated() {
    return !isNotAuthenticated();
}

export function isNotAuthenticated() {
    return !getUserId();
}

function getClient() {
    if (client == null) {
        client = createWhileCatClient(timezone)
    }
    return client
}
