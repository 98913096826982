import {css, html} from 'lit';
import {BaseTask} from '@whilecat/core/tasks/base-task.js';
import '@whilecat/core/editors/code-area.js';
import {publish} from "@whilecat/core/events/event-service.js";
import {TASK_COMPLETED} from "@whilecat/core/events/task-events.js";

export class TextBlockTask extends BaseTask {
    static styles = [
        super.styles,
        css`
          /* This fixes text selection in FF. */

          :host {
            display: block;
          }

          p {
            word-wrap: break-word;
            //max-width: 600px;
          }
        `,
    ];

    static properties = {
        data: {type: Object},
    };

    /**
     * Constructor
     * @param id - id of a task
     * @param {Object} data
     */
    constructor(id, data) {
        super();
        this.id = id;
        this.data = data;
    }

    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);
        this._completeTask()
    }

    _completeTask() {
        publish(TASK_COMPLETED, this.id);
    }

    render() {
        return html`
          <div>
            ${this.data.content}
          </div>
        `;
    }
}

customElements.define('text-block-task', TextBlockTask);
