import {Decoration, DecorationSet, EditorView, MatchDecorator, ViewPlugin, ViewUpdate} from "@codemirror/view"
import {PlaceholderWidget} from "@whilecat/core/editors/decorators/placeholder/placeholder-widget.ts";

export const PLACEHOLDER_REGEX = /\/\*@@(\w+)@@\*\//

const placeholderMatcher = new MatchDecorator({
    regexp: new RegExp(PLACEHOLDER_REGEX.source, 'g'),
    decoration: match => Decoration.replace({
        widget: new PlaceholderWidget(match[0]),
    })
})

export function placeholder(text: string) {
    return `/*@@${text}@@*/`
}

export const placeholders = ViewPlugin.fromClass(class {
    placeholders: DecorationSet

    constructor(view: EditorView) {
        this.placeholders = placeholderMatcher.createDeco(view)
    }

    update(update: ViewUpdate) {
        this.placeholders = placeholderMatcher.updateDeco(update, this.placeholders)
    }
}, {
    decorations: instance => instance.placeholders,
    provide: plugin => EditorView.atomicRanges.of(view => {
        return view.plugin(plugin)?.placeholders || Decoration.none
    })
})
