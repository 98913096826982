import {LevelInfo} from "@whilecat/services/dto/level-info.ts";
import {Course} from "@whilecat/services/dto/course.ts";
import type { paths } from "@whilecat/generated/openapi/course.ts"
import createClient from "openapi-fetch";

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
const client = createClient<paths>({ baseUrl: "/api", headers: {"TimeZone": timezone}});


async function getCoursesMeshRequest() {
    return await client.GET("/public/mesh/course")
}

export async function getCoursesMesh() {
    const {response, data} = await getCoursesMeshRequest();
    if (!data) {
        throw new Error(response.statusText);
    }

    return data.map(courseInfo => new Course(
        courseInfo.course.id,
        courseInfo.course.uuid,
        courseInfo.course.name,
        courseInfo.course.url,
        courseInfo.course.img_url,
        courseInfo.course.description,
        courseInfo.course.tags,
        courseInfo.enrolled)
    );
}

export async function getCourseLevels(courseUuid: string) {
    try {
        const {response, data} = await client.GET("/public/course/{courseUuid}/level", {params: {path: {courseUuid}}})

        if (!data) {
            throw new Error(response.statusText);
        }
        
        const levels = data.map(level => new LevelInfo(
            level.id,
            level.name,
            level.url,
            false)
        );
        return {ok: true, levels};
    } catch (e) {
        console.error(e)
        return {ok: false, levels: []};
    }
}