import {keymap} from "@codemirror/view";
import {defaultKeymap, insertTab} from "@codemirror/commands";

export function getKeymapExtension() {
    const customKeyMap = [
        {
            key: 'Meta-Enter',
            run: () => {
                return true
            },
            preventDefault: true,
        },
        {
            key: 'Ctrl-Enter',
            run: () => {
                return true
            },
            preventDefault: true,
        },
    ]

    return keymap.of([...customKeyMap, ...defaultKeymap, insertTab])
}