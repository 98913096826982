import {css, html, LitElement} from 'lit';

import {autoPlacement, computePosition, platform} from '@floating-ui/dom';

class TextNote extends LitElement {
    static properties = {
        href: {type: String}
    };

    static styles = css`
      :host {
        position: relative;
        display: inline-flex;
        border-bottom: 1px dotted var(--sl-color-warning-400);
        height: 18px;
      }

      .tooltip {
        visibility: hidden;
        position: absolute;
        color: var(--whilecat-dimmed-text-color);
        border-radius: 10px;
        border: 1px solid var(--sl-color-neutral-300);
        background-color: var(--sl-color-neutral-50);
        min-width: 200px;
        max-width: 600px;
        z-index: var(--sl-z-index-tooltip);
        white-space: collapse;
      }

      .content {
        margin: 10px;
        font-size: 12px;
      }

      .cm-content {
        font-family: var(--whilecat-mono-font)
      }

      .tooltip.show {
        visibility: visible;
      }
    `;

    constructor() {
        super();
        this.tooltip = null;
    }

    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);
        this.tooltip = this.shadowRoot.querySelector('.tooltip')

        this.addEventListener("mouseenter", this.showTooltip)
        this.addEventListener("mouseleave", this.hideTooltip)

        this.computePosition()
    }

    clearTimeOutIfSet(timeoutId) {
        if (timeoutId !== null) {
            clearTimeout(timeoutId);
        }
    }

    showTooltip() {
        this.clearTimeOutIfSet(this.showTimeoutId);
        this.showTimeoutId = setTimeout(() => {
            this.tooltip.classList.add('show');
            this.computePosition()
        }, 500)
    }

    computePosition() {
        computePosition(this, this.tooltip, {
            middleware: [
                autoPlacement({
                    allowedPlacements: ['top', 'bottom'],
                })
            ],
            strategy: this.strategy,
            platform: {
                ...platform,
            }
        }).then(({x, y, middlewareData, placement}) => {
                Object.assign(this.tooltip.style, {
                    left: `${x}px`,
                    top: `${y}px`,
                })
            }
        )
    }

    hideTooltip() {
        this.clearTimeOutIfSet(this.showTimeoutId);
        this.shadowRoot.querySelector('.tooltip').classList.remove('show');
    }

    render() {
        return html`
          <div class="tooltip">
            <div class="content">
              <slot name="tip"></slot>
            </div>
          </div>
          <slot></slot>
        `;
    }
}

customElements.define('text-note', TextNote);
