import {HttpStatus} from "@whilecat/services/response-codes.js";
import type { paths } from "@whilecat/generated/openapi/repetition.ts"
import createClient from "openapi-fetch";

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
const client = createClient<paths>({ baseUrl: "/api", headers: {"TimeZone": timezone}});


export async function getAllTasksToRepeat() {
    const {data} = await client.GET("/user/repetition");
    return data!;
}

export async function scheduleNextRepeat(courseUuid: string, levelUuid: string, taskUuid: string) {
    const payload = {courseUuid, levelUuid, taskUuid};
    const {response} = await client.PUT("/user/repetition", {body: payload})
    return response;
}

export async function hasSomethingToRepeat() {
    const {response} = await client.GET("/user/repetition/available")
    return response.status === HttpStatus.FOUND;
}