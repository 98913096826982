import {html} from "lit";

export function stringToListItem(data) {
    const paragraphs = data.split('\n')
        .filter(line => line.trim() !== '')
        .map(line => html`<li>${line}</li>`);

    return html`${paragraphs}`;
}

export function formatDuration(ms, skipMs) {
    let seconds = Math.floor(ms / 1000.0);
    const hours = Math.floor(seconds / 3600);
    seconds %= 3600;
    const minutes = Math.floor(seconds / 60);
    seconds %= 60;

    let formattedTime = "";

    if (hours > 0) {
        formattedTime += hours + " hr" + (hours > 1 ? "s " : " ");
    }
    if (minutes > 0) {
        formattedTime += minutes + " min" + (minutes > 1 ? "s " : " ");
    }
    if (seconds > 0) {
        formattedTime += seconds + " sec" + (seconds > 1 ? "s " : " ");
    }
    if (ms >= 0 && !skipMs) {
        formattedTime += ms + " ms";
    }

    return formattedTime.trim();
}

export function isBlank(string) {
    return string === undefined || string === null || string === '';
}

export function isNotBlank(string) {
    return !isBlank(string)
}

export function isNotBlankArray(array) {
    for (let i = 0; i < array.length; i++) {
        if (isBlank(array[i])) {
            return false;
        }
    }
    return true
}