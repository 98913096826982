import {css, CSSResult, html, LitElement} from "lit";
import '@whilecat/core/text/text-note.js'

class PlaceholderElement extends LitElement {

    static styles: CSSResult[] = [
        css`
          :host {
            display: inline-flex;
          }

          .placeholder {
            font-family: monospace;
            padding-right: 3px;
            padding-left: 3px;
            border: 2px solid var(--sl-color-cyan-400);
            border-bottom: none;
            border-top: none;
            border-radius: var(--sl-border-radius-medium);
            cursor: pointer;
            font-style: italic;
            color: var(--sl-color-cyan-600);
            position: relative;
            display: inline-block;
          }

          .placeholder:hover {
            color: var(--sl-color-cyan-800);
            border: 2px solid var(--sl-color-cyan-600);
            border-bottom: none;
            border-top: none;
          }
        `
    ]

    render() {
        return html`
          <div class="placeholder">Type here</div>
        `
    }
}

customElements.define('placeholder-element', PlaceholderElement);