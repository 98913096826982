import {requireNotNull} from "@whilecat/core/utils/validation.ts";
import type {paths} from "@whilecat/generated/openapi/java-compiler.ts"
import {Client} from "openapi-fetch";
import {createWhileCatClient} from "@whilecat/services/client-utils.js";

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
let client: Client<paths> | null = null

export const CodeType = {
    CLASS: "CLASS",
    SCRIPT: "SCRIPT",
    TEST: "TEST",
} as const;

type CodeTypeT = keyof typeof CodeType;


export async function compileCode(codeSnippet: string, codeType: CodeTypeT) {
    requireNotNull(codeSnippet, "code can't be null")
    requireNotNull(codeType, "code type can't be null")

    const payload = {
        type: codeType,
        code: codeSnippet
    };
    const {response, data} = await getClient().POST("/java/run", {body: payload})

    return {response, data};
}

function getClient() {
    if (client == null) {
        client = createWhileCatClient(timezone)
    }
    return client
}