import {css, html, LitElement} from 'lit';
import {basicSetup, EditorView} from "codemirror";
import {getTheme, WHILECAT_CODE_EDITOR_SYNTAX_HIGHLIGHTING} from "@whilecat/core/editors/code-area-theme.js";
import {indentUnit} from "@codemirror/language";
import {lineNumberMarkers} from "@codemirror/view";
import {Compartment, EditorState} from "@codemirror/state";
import {getLanguageExtensions} from "@whilecat/core/editors/configurators/extensions-factory.js";
import {tooltipExtension} from "@whilecat/core/editors/decorators/tooltip-extension.js";
import {getCheckBoxGutter} from "@whilecat/core/editors/gutters/line-marker-gutter.js";
import {getKeymapExtension} from "@whilecat/core/editors/extensions/keymap-extension.js";
import readOnlyRangesExtension from "@whilecat/core/editors/extensions/readonly-extension/read-only-extension.ts";

import "@whilecat/core/editors/buttons/copy-button.ts"
import "@whilecat/core/editors/buttons/reset-button.ts"
import "@whilecat/core/editors/buttons/show-answer-button.ts"
import "@whilecat/core/editors/buttons/view-button.ts"
import {placeholders} from "@whilecat/core/editors/decorators/placeholder-decorator.ts";

export class CodeArea extends LitElement {
    static styles = css`
      :host {
        display: flex;
        position: relative;
        align-items: center;
        background-color: var(--whilecat-code-editor-background-color);
        border-radius: 10px;
      }

      #container {
        position: relative;
        width: 100%;
      }

      .cm-editor, .cm-scroller {
        border-radius: 10px;
      }

      .actionButtonGroup {
        position: absolute;
        right: 15px;
        top: -3px;
        z-index: var(--sl-z-index-dialog);
      }

      sl-copy-button.actionButtonGroup::part(tooltip__base) {
        z-index: var(--sl-z-index-tooltip);
      }

      .annotation, .annotation .ͼq {
        color: #d0c658;
      }

      .readonly-code {
        opacity: 0.5;
      }

      .under-stroked {
        text-decoration-line: underline;
        text-decoration-style: wavy;
        text-decoration-thickness: 0.04rem;
        text-underline-offset: 0.2rem;
        text-decoration-color: var(--sl-color-cyan-600);
      }
    `;

    static properties = {
        code: {type: String},
        withCheckboxes: {type: Boolean},
        editable: {type: Boolean},
        linenumbers: {type: Boolean},
        disabled: {type: Boolean},
        language: {type: String},
        viewButtonEnabled: {type: Boolean},
        oneLineEditor: {type: Boolean},
        viewButtonCallBack: {type: Function},
        resetButtonCallBack: {type: Function},
        highlightedLines: {type: Array},
        tooltips: {type: Object},
        viewToggled: {type: Boolean},
        showAnswerButtonCallBack: {type: Function},
        showAnswerButtonVisible: {type: Boolean},
        showAnswerButtonEnabled: {type: Boolean},
        readonlyRanges: {type: Array}
    };

    constructor(code, withCheckboxes, editable, language, disabled) {
        super();
        this.code = code;
        this.withCheckboxes = withCheckboxes;
        this.editable = editable;
        this.language = language;
        this.disabled = disabled;
        this.viewButtonEnabled = false;
        this.linenumbers = true;
        this.editor = null;
        this.viewToggled = false;
        this.oneLineEditor = false;
        this.showAnswerButtonEnabled = false
        this.showAnswerButtonVisible = false
        this.checkBoxOptions = {
            processor: this.onCheckBoxSelect,
            selected: [],
            disabled: false,
        }
        this.highlightedLines = []
        this.tooltips = null
        this.editableCompartment = new Compartment;
        this.readonlyRanges = []
    }

    enableShowAnswerButton() {
        this.showAnswerButtonEnabled = true
    }

    setShowAnswerButtonCallBack(showAnswerButtonCallBack) {
        this.showAnswerButtonCallBack = showAnswerButtonCallBack
    }

    setViewButtonCallBack(viewButtonCallBack) {
        this.viewButtonCallBack = viewButtonCallBack;
    }

    setResetButtonCallBack(resetButtonCallBack) {
        this.resetButtonCallBack = resetButtonCallBack;
    }

    onCheckBoxSelect() {
        this.checkBoxOptions.selected = this.getMarkedLines();
    }

    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);

        const container = this.shadowRoot.getElementById("container");

        this.editor = new EditorView({
            doc: this.code,
            lineNumbers: false,
            extensions: this.getExtensions(),
            parent: container,
        })

        this.editor.customOptions = this.checkBoxOptions;

        this.setupReadOnlyCodeHoverHandlers()
    }

    /**
     * Setups listeners to mouse enter event to make fully visible readonly lines
     * when any of them is hovered.
     */
    setupReadOnlyCodeHoverHandlers() {
        const elements = this.shadowRoot.querySelectorAll('.readonly-code');

        elements.forEach(element => {
            element.addEventListener('mouseenter', () => {
                elements.forEach(el => el.style.opacity = '1');
            });

            element.addEventListener('mouseleave', () => {
                elements.forEach(el => el.style.opacity = '');
            });
        });
    }

    setEditable(editable) {
        this.editor.dispatch({
            effects: this.editableCompartment.reconfigure(EditorView.editable.of(editable))
        });
    }

    getExtensions() {
        let extensions = getLanguageExtensions(this.language).concat([
            basicSetup,
            getTheme(this.oneLineEditor),
            WHILECAT_CODE_EDITOR_SYNTAX_HIGHLIGHTING,
            EditorView.lineWrapping,
            indentUnit.of("    "),
            EditorState.readOnly.of(!this.editable),
            this.editableCompartment.of(EditorView.editable.of(this.editable)),
            placeholders,
        ]);

        extensions = this.addTooltipsExtension(extensions)

        if (this.withCheckboxes) {
            extensions = [getCheckBoxGutter()].concat(extensions);
        }

        if (!this.linenumbers) {
            extensions.push(lineNumberMarkers.of())
        }

        if (this.readonlyRanges?.length > 0) {
            extensions = [extensions].concat(
                readOnlyRangesExtension((targetState/*:EditorState*/) => {
                    return this.getReadOnlyRanges(targetState)
                })
            )
        }

        // keymap should always come first to catch events before default ones
        return [getKeymapExtension(), ...extensions];
    }

    getReadOnlyRanges(targetState/*:EditorState*/) /*:Array<{from:number|undefined, to:number|undefined}> =>*/ {
        return this.readonlyRanges.map((range) => range.getRange(targetState))
    }

    addTooltipsExtension(extensions) {
        if (this.tooltips === null || this.tooltips === undefined) {
            return extensions
        }

        if (Object.keys(this.tooltips).length > 0) {
            return extensions.concat(
                tooltipExtension(this.tooltips)
            )
        }
        return extensions
    }

    update(changedProperties) {
        super.update(changedProperties);
    }

    getMarkedLines() {
        return this.checkBoxOptions.selected;
    }

    markLines(lines) {
        if (!this.withCheckboxes) {
            return;
        }
        this.checkBoxOptions.selected = [...lines];
        this.requestUpdateEditor()
    }

    // FIXME: not really a good solution repasting current code
    requestUpdateEditor() {
        this.editor.dispatch({
            changes: {
                from: 0,
                to: this.editor.state.doc.length,
                insert: this.code
            }
        })
    }

    getCode() {
        return this.editor.state.doc.toString()
    }

    setCode(code) {
        this.code = code;
        if (this.editor) {
            this.editor.dispatch({
                changes: {
                    from: 0,
                    to: this.editor.state.doc.length,
                    insert: code
                }
            })
        }
    }

    disable() {
        this.disabled = true;
        this.checkBoxOptions.disabled = true;
        const elements = this.shadowRoot.querySelectorAll('#line-checkbox');
        elements.forEach((element) => {
            element.disabled = true;
        });
    }

    toggleViewButton() {
        this.viewToggled = !this.viewToggled;
        this.viewButtonCallBack(this.viewToggled);
    }

    getActionButtons() {
        return html`
          <div class="actionButtonGroup">
            <show-answer-button .visible="${this.showAnswerButtonVisible}"
                                .codeArea="${this}"
                                .showAnswerCallBack="${this.showAnswerButtonCallBack}"
                                .disabled="${!this.showAnswerButtonEnabled}"
            ></show-answer-button>
            <view-button .visible="${this.viewButtonEnabled}"
                         .viewButtonCallBack="${() => this.toggleViewButton()}"
                         .codeArea="${this}"
            ></view-button>
            <reset-button .visible="${this.editable || this.viewToggled}"
                          .resetButtonCallBack="${this.resetButtonCallBack}"
                          .codeArea="${this}"
                          .disabled="${this.viewToggled}"
            ></reset-button>
            <copy-button .codeArea="${this}"></copy-button>
          </div>
        `
    }

    render() {
        return html`
          <div id="container">

          </div>
          ${this.getActionButtons()}
        `;
    }
}

customElements.define('code-area', CodeArea);
