import {css, html, LitElement} from 'lit';
import '@whilecat/core/loaders/loading-icon.js'

class RunButton extends LitElement {

    static styles = css`
      :host {
        display: block;
        padding: 5px;
      }
    `;

    static properties = {
        processing: {type: Boolean},
        buttonText: {type: String},
        disabled: {type: Boolean},
        onClickFunction: {},
    };

    constructor() {
        super();
        this.processing = false;
        this.buttonText = "";
    }

    render() {
        if (this.processing) {
            return html`
              <sl-button variant="primary" outline ?disabled="${this.disabled}">
                <loading-icon id="loading"></loading-icon>
              </sl-button>
            `;
        }
        return html`
          <sl-button variant="primary" outline ?disabled="${this.disabled}" @click="${this.onClickFunction}">
            ${this.buttonText}
            <sl-icon name="arrow-return-right" label="Settings"></sl-icon>
          </sl-button>
        `
    }
}

customElements.define('run-button', RunButton);
