export function requireNotNull<T>(value: T | null | undefined, message?: string): asserts value is T {
    sanityCheck(value !== null && value !== undefined, message || "Value is required to be not blank");
}

export function requireNotBlank(value: string | null | undefined, message?: string): string {
    requireNotNull(value)
    sanityCheck(value !== '', message || "Value is required to be not blank")
    return value;
}

export function sanityCheck(boolean: boolean, message: string): asserts boolean is true {
    if (!boolean) {
        throw new Error(message);
    }
}

export function requireNoCall(message: string) {
    sanityCheck(false, message)
}