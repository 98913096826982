export function getCSSVariable(variable) {
    return getComputedStyle(document.documentElement).getPropertyValue(variable);
}

export function getCSSVariablePixels(variable) {
    return Number(getCSSVariable(variable).replace("px", ""));
}

export function scrollToBottom() {
    window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
    });
}

