import {css, html, LitElement, nothing} from 'lit';
import '@whilecat/core/utils/element-utils.js';
import '@whilecat/core/tasks/task-structure.ts';
import '@whilecat/core/tasks/quiz-task.js';
import '@whilecat/core/tasks/complete-sentence-task.js';
import '@whilecat/core/tasks/text-block-task.js';
import '@whilecat/core/tasks/mark-lines-task.js';
import '@whilecat/core/tasks/line-input-task.js';
import '@whilecat/core/tasks/java/code-line-input-task.js';
import '@whilecat/core/tasks/java/code-editor-task.js';
import {publish} from "@whilecat/core/events/event-service.js";
import {ANCHOR_SELECTED, LEVEL_ACTION_REQUIRED} from "@whilecat/core/events/task-events.js";

class LevelNavigation extends LitElement {
    static styles = css`
      :host {
        display: block;
        color: var(--sl-color-gray-500);
      }

      .navigation-panel {
        position: sticky;
        top: var(--whilecat-page-header-magin);
        margin-right: 100px;
        margin-left: 50px;

        display: grid;
        justify-items: center;

        background-color: var(--whilecat-background-color);
        backdrop-filter: blur(var(--whilecat-default-blur));
        border: var(--whilecat-default-border-size) solid var(--sl-color-neutral-100);
        border-radius: var(--sl-border-radius-x-large);
        border-top: none;
        border-bottom: none;

        min-width: 250px;
        min-height: 200px;
      }

      .navigation-panel:hover {
        border: var(--whilecat-default-border-size) solid var(--sl-color-primary-100);
        border-top: none;
        border-bottom: none;
      }

      .progress-ring, .progress-ring-completed {
        height: 50px;
        width: 50px;
        --size: 50px;
        margin: .5rem;
      }

      .progress-ring {
        --indicator-color: var(--sl-color-primary-400);
      }

      .progress-ring-completed {
        --indicator-color: var(--sl-color-green-600);
        animation: waveAnimation 1s;
      }

      @keyframes waveAnimation {
        0% {
          border-radius: 100px;
          box-shadow: 0 0 0 0 var(--sl-color-green-600)
        }
        50% {
          border-radius: 100px;
          box-shadow: 0 0 10px 5px var(--sl-color-green-600)
        }
        100% {
          box-shadow: 0 0 10px 10px transparent;
        }
      }

      sl-divider {
        min-width: 100px;
        margin-bottom: 0;
      }

      .anchors, .actions {
        list-style-type: '- ';
        color: var(--sl-color-gray-400);
      }

      .anchor, .action {
        justify-self: start;
      }

      sl-button.anchor::part(base) {
        color: var(--sl-color-gray-400)
      }

      sl-button.anchor::part(base):hover {
        color: var(--sl-color-gray-700)
      }

    `;

    static properties = {
        completedCount: {type: Number},
        totalCount: {type: Number},
        navigationAnchors: {type: Array},
        actions: {type: Array},
    };

    /**
     * Constructor
     */
    constructor(completedCount, totalCount, navigationAnchors) {
        super();
        this.completedCount = completedCount;
        this.totalCount = totalCount;
        this.navigationAnchors = navigationAnchors;
        this.actions = []
    }

    getPercent() {
        if (this.totalCount === 0) return 0;
        return Math.round(this.completedCount / this.totalCount * 100);
    }

    goToAnchor(anchor) {
        publish(ANCHOR_SELECTED, anchor);
    }

    sendActionEvent(action) {
        publish(LEVEL_ACTION_REQUIRED, action);
    }

    getProgressRingClass() {
        return this.getPercent() !== 100 ? "progress-ring" : "progress-ring-completed";
    }

    getPercentText() {
        return this.getPercent() !== 100 ? `${this.getPercent()}%` :
            html`
              <sl-icon name="check-lg" style="color: var(--sl-color-green-600); font-size: 32px;"></sl-icon>`;
    }

    getAnchors() {
        return this.navigationAnchors.length > 0 ? html`
          <ul class="anchors">
            ${this.navigationAnchors.map((anchor) => html`
              <li>
                    <sl-button class="anchor" variant="text" size="small" @click=${() => this.goToAnchor(anchor)}>
                      ${anchor}
                    </sl-button>
                  </li>
            `)}
          </ul>
        ` : nothing;
    }

    getActions() {
        return this.actions.length > 0 ? html`
          <div class="actions">
            ${this.actions.map((action) => html`
              <sl-button class="action" variant="text" @click=${() => this.sendActionEvent(action)}>
                  ${action}
                </sl-button>
            `)}
          </div>
        ` : nothing;
    }

    render() {
        return html`
          <div class="navigation-panel">
              <sl-progress-ring value="${this.getPercent()}" class="${this.getProgressRingClass()}">
                ${this.getPercentText()}
              </sl-progress-ring>
            <h5>Completed:</h5>
            <div>${this.completedCount} of ${this.totalCount} tasks</div>
            <sl-divider></sl-divider>
            ${this.getAnchors()}
            ${this.getActions()}
          </div>
        `;
    }
}

customElements.define('level-navigation', LevelNavigation);
