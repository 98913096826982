import {css, html, LitElement, nothing} from 'lit';
import {getCSSVariablePixels} from "@whilecat/core/utils/screen-utils.js";
import {hasSomethingToRepeat} from "@whilecat/services/repetition-service.ts";
import {isAuthenticated} from "@whilecat/services/authentication-service.ts";

const minPageBigScreenWidth = getCSSVariablePixels('--whilecat-min-page-width-big-screen');

class Navpanel extends LitElement {
    static styles = css`

      .small-menu-btn {
        display: block;
        z-index: 1000;
        position: fixed;
        left: 0;
        top: 0;
        margin: 10px;
        transition: transform 0.3s ease;
      }

      .small-menu-btn.rotate {
        transform: rotate(90deg);
      }

      .small-menu-btn.rotate::part(base) {
        border: none;
      }

      .navbar {
        position: fixed;
        left: 0;
        top: 0;
        width: var(--whilecat-navigation-panel-width);
        height: 100%;
        overflow: auto;
        border-right: solid var(--sl-color-gray-50);
        background-color: var(--whilecat-background-color);
        z-index: var(--sl-z-index-drawer);
      }

      .navbar .menu-btn {
        display: block;
        padding: 16px;
      }

      sl-button.menu-btn::part(base) {
        color: var(--whilecat-dimmed-text-color);
      }

      sl-button.menu-btn::part(base):hover {
        color: var(--sl-color-primary-700);
      }

      .navbar .links {
        text-align: center;
      }

      .navbar .brand {
        display: block;
        width: 100%;
        padding-top: 20px;
        text-align: center;
        color: var(--sl-color-primary-600);
        font-family: 'Shadows Into Light', cursive;
      }

      .navbar .section-text {
        margin-left: 20px;
        color: var(--sl-color-neutral-400);
        font-family: var(--whilecat-default-font);
      }

      @keyframes slideIn {
        from {
          transform: translateX(-100%);
        }
        to {
          transform: translateX(0);
        }
      }

      @keyframes slideOut {
        from {
          transform: translateX(0);
        }
        to {
          transform: translateX(-100%);
        }
      }

      .show {
        animation: slideIn 0.5s ease forwards;
      }

      .hidden {
        animation: slideOut 0.5s ease forwards;
      }

      .notification {
        position: relative;
        border-radius: 10px;
        font-size: 13px;
        color: var(--sl-color-green-600);
        top: -5px;
        left: -7px;
      }
    `;

    static properties = {
        hiddenByDefault: {type: Boolean},
        smallScreen: {type: Boolean},
        repetitionAvailable: {type: Boolean},
    };

    constructor() {
        super();
        this.hiddenByDefault = false;
        this.smallScreen = this.isSmallScreen();
        this.repetitionAvailable = false
        setInterval(async () => {
            if (isAuthenticated()) {
                this.repetitionAvailable = await hasSomethingToRepeat();
            }
        }, 60_000);

        window.addEventListener('resize', () => {
            this.smallScreen = this.isSmallScreen();
        })
    }

    firstUpdated(changedProperties) {
        super.firstUpdated(changedProperties);
        this.smallScreen = this.isSmallScreen();
    }

    showDrawerMenu() {
        let menuButton = this.shadowRoot.getElementById("menu-btn");

        if (menuButton.classList.contains("rotate")) {
            this.hide();
        } else {
            this.show();
        }
    }

    isSmallScreen() {
        return (window.innerWidth <= minPageBigScreenWidth) || this.hiddenByDefault;
    }

    getMenuClass(smallScreen) {
        return smallScreen ? "hidden" : "";
    }

    getSmallButtonClass(smallScreen) {
        return smallScreen ? "" : "rotate";
    }

    hide() {
        let menu = this.shadowRoot.getElementById("menu-block");
        let menuButton = this.shadowRoot.getElementById("menu-btn");

        menuButton.classList.remove("rotate");
        menu.classList.remove("show");
        menu.classList.add("hidden");
    }

    show() {
        let menu = this.shadowRoot.getElementById("menu-block");
        let menuButton = this.shadowRoot.getElementById("menu-btn");

        menuButton.classList.add("rotate");
        menu.classList.add("show");
        menu.classList.remove("hidden");
    }

    getUserMenu() {
        if (isAuthenticated()) {
            return html`
              <sl-divider></sl-divider>
              <small class="section-text">My</small>
              <sl-button class="menu-btn" variant="text" size="small" href="/your-courses">
                <sl-icon slot="prefix" name="card-list"></sl-icon>
                My Courses
              </sl-button>
              <sl-button class="menu-btn" variant="text" size="small" href="/repetition">
                <sl-icon slot="prefix" name="arrow-repeat"></sl-icon>
                Repetition
                ${this.repetitionAvailable ? html`
                  <sl-icon class="notification" slot="suffix" name="stars"></sl-icon>` : nothing}
              </sl-button>
            `
        }
        return nothing;
    }


    render() {
        return html`
          <sl-button id="menu-btn" class="small-menu-btn ${this.getSmallButtonClass(this.smallScreen)}" slot="trigger"
                     size="small" pill
                     @click="${() => this.showDrawerMenu()}">
            <sl-icon slot="prefix" name="list"></sl-icon>
          </sl-button>
          <div id="menu-block" class="navbar ${this.getMenuClass(this.isSmallScreen()) ? "hidden" : "show"}">
            <!-- Brand -->
            <h1 class="brand">WhileCat</h1>

            <!-- External links -->
            <div class="links">
              <sl-button variant="default" size="small" circle>
                <sl-icon name="medium" label="Medium"></sl-icon>
              </sl-button>
              <sl-button variant="default" size="small" circle>
                <sl-icon name="instagram" label="Instagram"></sl-icon>
              </sl-button>
              <sl-button variant="default" size="small" circle>
                <sl-icon name="twitter-x" label="Settings"></sl-icon>
              </sl-button>
            </div>

            <!-- Main menu -->
            ${this.getUserMenu()}
            <sl-divider></sl-divider>
            <small class="section-text">General</small>
            <sl-button class="menu-btn" variant="text" size="small" href="/courses">
              <sl-icon slot="prefix" name="card-list"></sl-icon>
              Courses
            </sl-button>
            <sl-button class="menu-btn" variant="text" size="small" href="/prices">
              <sl-icon slot="prefix" name="wallet2"></sl-icon>
              Pricing
            </sl-button>

            <!-- Help -->
            <sl-divider></sl-divider>
            <small class="section-text">Info</small>
            <sl-button class="menu-btn" variant="text" size="small" href="/about">
              <sl-icon slot="prefix" name="info-circle"></sl-icon>
              About
            </sl-button>
            <sl-button class="menu-btn" variant="text" size="small" href="/support">
              <sl-icon slot="prefix" name="question-circle"></sl-icon>
              Support
            </sl-button>
            <sl-button class="menu-btn" variant="text" size="small" href="/reach-us">
              <sl-icon slot="prefix" name="envelope-paper-heart"></sl-icon>
              Reach us
            </sl-button>
          </div>
        `;
    }
}

customElements.define('navigation-panel', Navpanel);
