export function objectsEqual(object1, object2) {
    return JSON.stringify(object1) === JSON.stringify(object2)
}

export function objectCopy(object) {
    return JSON.parse(JSON.stringify(object));
}

export function isPresent(object) {
    return object !== null && object !== undefined
}

export function getOr(object, defaultValue) {
    return isPresent(object) ? object : defaultValue
}